import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function DlgScriptCancel({showScriptCancellation, setShowScriptCancellation, thisScript, person}) {
    const [processing, setProcessing]                     = useState(false);

    const txtReasonRef                                    = useRef();

    async function handleEditingScript(e) {
        e.preventDefault();  
        setProcessing(true);  
        try{

            const newDataSet = {
                "userid": person._id,
                "scriptid": thisScript._id,
                "message": txtReasonRef.current.value
            }

            const resCancel = await axios.put(CONSTANTS.API_URL +"scripts/updating/cancellation/details", newDataSet ,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
  
            //console.log("|||||||||");
            //console.log(resCancel);

            if(resCancel.status == 200){
                txtReasonRef.current.value = "";
                toast.success("Script has been cancelled successfully.");
                setShowScriptCancellation(false);
            }else {
                toast.error("Something went wrong, please try again later.")
            }
            //console.log(resCancel.data);
            

            setProcessing(false);  
        }catch(err){
            setProcessing(false);  
            console.log(err);
            toast.error("Something went wrong, please try again later.")
        }
    }

  return (
    <Dialog header="Cancel Script" visible={showScriptCancellation} onHide={() => setShowScriptCancellation(false)} style={{ width: '50vw' }}
      footer={
            <div>      
                <Button className="btn btn-rescure" label="Close" onClick={() => setShowScriptCancellation(false)} />
            </div>
            }>
        <div className="modal-body-content">
            <div className="tok-info">
               <form onSubmit={handleEditingScript}>
                    <div className="form-group">
                        <div className="lbldesc">Reason for cancelling*:</div>
                        <textarea className="form-control" ref={txtReasonRef} required></textarea>
                    </div>
                    <div className="form-group">
                            <button type="submit" className="btn btn-rescure" disabled={processing}>Submit Cancellation</button>                             
                    </div>   
               </form>
            </div>
            {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                    </div>
                )
            }  
        </div>
     </Dialog>
  )
}

export default DlgScriptCancel