import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faThumbsDown, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons'


function DlgScriptExtension({showScriptClientExtension, setShowScriptClientExtension, thisScript, person}) {
    const [processing, setProcessing]                                = useState(false);
    const [downloaNew, setDownloadNew]                               = useState('');
    const [dateFieldExtended, setDateFieldExtended]                  = useState(false);

    useEffect(() =>{
        if(showScriptClientExtension){
            updateDatesFunction();
        }
    },[showScriptClientExtension])
    
    const updateDatesFunction = () => {
        const futureDate = new Date(thisScript.expiryDate);
        console.log("Initial Date");
        console.log(futureDate);
        futureDate.setDate(futureDate.getDate() + 30);
        console.log("Extented Date");
        console.log(futureDate);
        const theDate = futureDate.toISOString().split('T')[0];
        setDateFieldExtended(theDate);
    }

    const handleChangeDate = (value) => {
        console.log(value);
        setDateFieldExtended(value);
       //setFormValues({ ...formValues, [field]: value });
   };

   const executeExtendedUpdate = async () => {
    /*
      console.log("<=======================>");
      console.log("The ID");
      console.log(thisScript._id);

      console.log("Version Number");
      console.log(thisScript.version);

      console.log("Version Code");
      console.log(thisScript.versionCode);

      console.log("Date Extended");
      console.log(dateFieldExtended);
      console.log("_____==================>");
      */
      const newScriptExtension = {
        "archive": thisScript,
        "newDate": dateFieldExtended
      }

      const resNewScript = await axios.post(CONSTANTS.API_URL +"scripts/extended/script/formation", newScriptExtension,{
            headers: {
                token: "Bearer "+ person.accessToken
            }
        });

        console.log(resNewScript);
        console.log(resNewScript.data);
        setDownloadNew(resNewScript.data._id);
        setShowScriptClientExtension(false);
        ////////////
   }
  return (
    <Dialog header="Extent This Script" visible={showScriptClientExtension} onHide={() => setShowScriptClientExtension(false)} style={{ width: '75vw' }}
      footer={
            <div>      
                <Button className="btn btn-rescure" label="Close" onClick={() => setShowScriptClientExtension(false)} />
            </div>
            }>
        <div className="modal-body-content">
            {
                thisScript && (
                    <div className="tok-info">
                        <div className="row row-bows">
                            <div className="col-md-4">
                                <div className="dotted-mods">
                                    <div className="lab-tab">
                                       <span>Name:</span> <span className="span-label">{thisScript.fullname}</span>
                                    </div>
                                    <div className="lab-tab">
                                       <span>ID Number:</span> <span className="span-label">{thisScript.idNumber}</span>
                                    </div>
                                    <div className="lab-tab">
                                       <span>Medical Aid:</span> 
                                        {
                                            thisScript.medical.medicalAidOption ?
                                               <>
                                                <span className="span-label">Yes</span>
                                                <div className="p-label">Medical Aid Number: <span className="span-label">{thisScript.medical.medicalAidNumber}</span></div>
                                                <div className="p-label">Medical Aid Name: <span className="span-label">{thisScript.medical.medicalAidName}</span></div>
                                               </>
                                            :
                                            <span className="span-label">No</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dotted-mods">
                                    <div className="lab-tab">
                                       <span>Script Code:</span> <span className="span-label">{thisScript.scriptcode}</span>
                                    </div>
                                    <div className="lab-tab">
                                       <span>Version:</span> <span className="span-label">{thisScript.version} / {thisScript.versionCode} </span>
                                    </div>
                                    <div className="lab-tab">
                                       <span>Valid Until:</span> <span className="span-label">{new Date(thisScript.expiryDate).toLocaleDateString(undefined, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })} 
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dotted-mods">
                                    <div className="lab-tab">
                                      Next Valid Until:
                                      {
                                        dateFieldExtended && (
                                            <input
                                                    type="date"
                                                    placeholder="Expiry Date (30 days)"
                                                    value={dateFieldExtended}  
                                                    onChange={(e) => handleChangeDate( e.target.value)}
                                                    className="form-control"
                                                />
                                        )
                                      }
                                      
                                    </div>
                                    <div className="lab-tab mgtop10">
                                        <Button
                                           label="Extent Updated"
                                           className="btn btn-rescure btsm1"
                                           onClick={() => executeExtendedUpdate()}
                                         />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            downloaNew.length > 1 && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="pd10">
                                            You have created an extension <Link to={"/menuscript/" +downloaNew}>View Now</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                     
                    </div>
                )
            } 
            {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                    </div>
                )
           }           
        </div>
     </Dialog>
  )
}

export default DlgScriptExtension