import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FaArrowRight } from 'react-icons/fa'

function DlgScriptEdit({showScriptClientEdit, setShowScriptClientEdit, thisScript, person}) {

    const [processing, setProcessing]                            = useState(false);
    const [editedPrescriptions, setEditedPrescriptions]          = useState();
    const [prescriptInitialize, setPrescriptInitialize]          = useState(false);

    const [showHasMedicalInfo, setHasShowMedicalInfo]                            = useState(false);
    const [giveEditReason, setGivEditReason]                                     = useState(false);

    const [tokenNotReady, setTokenNotReady]                                      = useState(false);
    const [showFirstAlternatives, setShowFirstAlternatives]                      = useState(false);
    const [showFirstAlternativesAdvance, setShowFirstAlternativesAdvance]        = useState(false);

    const [selectedMedicine, setSelectedMedicine]                                = useState("");
    const [selectedIndexMeds, setSelectedIndexMeds]                              = useState(-1);
    const [medicineAlternativeList, setMedicineAlternativeList]                  = useState([]);
    const [medicineAlternativeListAdvance, setMedicineAlternativeListAdvance]    = useState([]);

    const [currentToken, setCurrentToken]                                        = useState(false);

    const editReasonRef                                 = useRef();

    useEffect(() => {
      if(showScriptClientEdit){
        collectCurrenttoken();
      }
    },[showScriptClientEdit])

    const collectCurrenttoken = async () => {

      try{
        setTokenNotReady(true);
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: CONSTANTS.API_URL + "settings/single",
              headers: { 
                'token': 'Bearer ' + person.accessToken
              }
            };
            
          //))))))))
          const response = await axios.request(config);
          //))))))))         
          setCurrentToken(response.data.medicationToken);
          setTokenNotReady(false);
      }catch(err){
          console.log(err);
          setTokenNotReady(false);
      }
    }

    useEffect(() => {
      if(thisScript){
        if(!prescriptInitialize){
          setEditedPrescriptions(thisScript.prescriptions);
          setHasShowMedicalInfo(thisScript.medical.medicalAidOption);
          setPrescriptInitialize(true);
          //If the script is nolonger in Initialization that  you have to give reason for edit
          if(thisScript.status.text != "Initialized"){
            setGivEditReason(true);
          }          
        }        
      }
     
    },[thisScript])

    useEffect(() => {
      if(selectedMedicine.length > 0){
        //fecth token
        fetchAlternativeMedicine();        
      }
    },[selectedIndexMeds])

    const fetchAlternativeMedicine = async () => {
      try{
        setShowFirstAlternatives(true);
        const obSearch = {
          "searching" : selectedMedicine
        }
        
        setMedicineAlternativeList([]);
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: CONSTANTS.API_CJMED +"medicine/search/regular/start",
            headers: { 
              'api_key': currentToken, 
              'Content-Type': 'application/json'
            },
            data : obSearch
          };

        //))))))))
        const resData = await axios.request(config);
        setMedicineAlternativeList(resData.data);
        setMedicineAlternativeListAdvance([])
        setShowFirstAlternatives(false);
      }catch(err){
        console.log(err);
        setShowFirstAlternatives(false);
      }
    }

    const handleAdvancedAlternativeSearch = async () => {
      try{
        setShowFirstAlternativesAdvance(true);
        const obSearch = {
          "searching" : selectedMedicine
        }
        
        setMedicineAlternativeListAdvance([]);
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: CONSTANTS.API_CJMED +"medicine/search/regular/contian",
            headers: { 
              'api_key': currentToken, 
              'Content-Type': 'application/json'
            },
            data : obSearch
          };

        //))))))))
        const resData = await axios.request(config);
        setMedicineAlternativeListAdvance(resData.data);
      
       setShowFirstAlternativesAdvance(false);
      }catch(err){
        console.log(err);
        setShowFirstAlternativesAdvance(false);
      }
    }
    const handlePrescriptionChange = (index, newValue) => {

        const updatedPrescriptions = [...editedPrescriptions];
        updatedPrescriptions[index] = newValue;
        setEditedPrescriptions(updatedPrescriptions);
    };
    
    const handleRemovePrescription = (index) => {
        const updatedPrescriptions = [...editedPrescriptions];
        updatedPrescriptions.splice(index, 1);
        setEditedPrescriptions(updatedPrescriptions);

        setSelectedIndexMeds(-1);
        setSelectedMedicine("")
    };

    const editMedicationItem = (index, stringEditMedic) => {

       setSelectedIndexMeds(index);
       setSelectedMedicine(stringEditMedic)
    }
    
  const handleCallSwapMedication = (alternative) => {
      setSelectedMedicine(selectedMedicine);
      const stringMedication = alternative.proprietaryName + " (" + alternative.activeIngredient + ") " + alternative.packSize + alternative.dosage +" "+ alternative.unit;
      
      const newPrescriptions = [...editedPrescriptions];

      // Update the specific index with the new value
      newPrescriptions[selectedIndexMeds] = stringMedication;
      setEditedPrescriptions(newPrescriptions);      
  }

  const handleCallSwapAdvancedMedication = (alternative) => {
    setSelectedMedicine(selectedMedicine);
    const stringMedication = alternative.proprietaryName + " (" + alternative.activeIngredient + ") " + alternative.packSize + alternative.dosage +" "+ alternative.unit;
    
    const newPrescriptions = [...editedPrescriptions];

    // Update the specific index with the new value
    newPrescriptions[selectedIndexMeds] = stringMedication;
    setEditedPrescriptions(newPrescriptions);      
}


  
  async function handleEditingScript(e) {
      e.preventDefault();  
      setProcessing(true);  
      try{
      
          setProcessing(false);  

          var reasonText = ""; 
          
          if(giveEditReason){
              reasonText = editReasonRef.current.value;
            }

          //////////////
          var mainUpdate = {
            "data": {
              "prescriptions": editedPrescriptions,
            },
            "scriptid": thisScript._id,
            "userid" : person._id,
            "reason" : giveEditReason,
            "reasonText" : reasonText,
            "key": thisScript.aws.key
          }

          /*
          const resScript = await axios.put(CONSTANTS.API_URL +"scripts/updating/calligraphy/details", mainUpdate,{
              headers: {
                  token: "Bearer "+ person.accessToken
              }
          });
          */
          const resScript = await axios.put(CONSTANTS.API_URL +"scripts/updating/prescritions/details", mainUpdate,{
            headers: {
                token: "Bearer "+ person.accessToken
            }
        });
          //if giveEditReason then delete the current pdf in order to prepare for a new one.
          //update viewing of download as well.
          toast.success("Update has been completed.");
          /*
          if(resScript.data.delete != null){
          // console.log();
            setEditedScript(true);
          }
          */
          setProcessing(false);  
      }catch(err){
          setProcessing(false);  
          console.log(err);
          toast.error("Something went wrong, please try again later.");
      }
  }

  return (
        <Dialog header="Edit Script" visible={showScriptClientEdit} onHide={() => setShowScriptClientEdit(false)} style={{ width: '80vw' }}
          footer={
                <div>      
                    <Button className="btn btn-rescure" label="Close" onClick={() => setShowScriptClientEdit(false)} />
                </div>
            }>
        <div className="modal-body-content">
            <div className="tok-info">
                <div className="form-blocks ">
                {
                     thisScript && (  
                     <form onSubmit={handleEditingScript}>  
                        <div className="row frow">                 
                          <div className="col-md-12">
                                {
                                  giveEditReason && (
                                    <>
                                       <div className="sm-label">Request Edit</div>
                                       <div className="form-group">
                                            <div className="lbldesc">Give Reason for Edit*:</div>
                                            <textarea className="form-control" ref={editReasonRef} required></textarea>
                                       </div>
                                    </>
                                  )
                                }                                         
                               
                        </div>                

                        <div className="col-md-4">
                           <div className="form-group">
                                    <div className="sm-label">Medication:</div>             
                                    <div className="list-prescript">
                                        {
                                            editedPrescriptions && (
                                                editedPrescriptions.map((thePres, i) => {
                                                  return (<div className="part-prescript" key={i}>     
                                                           <button type="button" 
                                                              className="btn btn-close-ls posi-close " 
                                                              onClick={() => handleRemovePrescription(i)}>
                                                                X
                                                            </button>
                                                           <input
                                                                type="text"
                                                                className="form-control wide80 editreadOnly"
                                                                value={thePres}
                                                                onChange={(e) => handlePrescriptionChange(i, e.target.value)}
                                                                readOnly={true}
                                                            />
                                                            <button type="button" 
                                                              className="btn btn-transfer " 
                                                              onClick={() => editMedicationItem(i, thePres)}
                                                              disabled={tokenNotReady}
                                                              >
                                                                <FaArrowRight />     
                                                            </button>
                                                    </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>                       
                              </div> 
                            
                        </div>
                        <div className="col-md-4">                
                        {
                          selectedMedicine.length > 0 &&
                          <div className="dossage-work">
                             <strong>{selectedMedicine}</strong>
                             <p>Please select alternative</p>
                             <div className="list-alternatives">
                                 
                                  {
                                    medicineAlternativeList && medicineAlternativeList.length > 0 ?
                                    <div className="">
                                      <ul className="list-items-cont">                                        {
                                            medicineAlternativeList.map((alternativeItem, index) => (
                                              <li key={index} className="colors-alt" onClick={() => handleCallSwapMedication(alternativeItem)}>
                                                {alternativeItem.proprietaryName}
                                              </li>                                      
                                            ))
                                          }
                                        </ul>

                                    </div>
                                    :
                                    <div className="">                                      
                                        {
                                          showFirstAlternatives ? (
                                              <div className="img-source">
                                                  <img src={CONSTANTS.SMALLER_PROCESS} className="inn-process" />
                                              </div>
                                              )
                                            :
                                            <h3>Nothing found</h3>
                                        }                                 
                                     
                                    </div>
                                  }
                             </div>
                          </div>
                        }
                               
                        </div>
                        <div className="col-md-4">
                            <div className="dossage-work-advance">
                                 <p><strong>Advanced Search</strong></p>
                                 {
                                  selectedMedicine.length > 0 && (
                                    <div className="advanced-edit">
                                       <button type="button" className="btn btn-gray" onClick={() => handleAdvancedAlternativeSearch()}>Search Now</button>
                                    </div>
                                  )
                                 }
                                {
                                  showFirstAlternativesAdvance && (
                                    <div className="img-source">
                                        <img src={CONSTANTS.SMALLER_PROCESS} className="inn-process" />
                                    </div>
                                    )
                                }
                                
                                {
                                    medicineAlternativeListAdvance && 
                                    medicineAlternativeListAdvance.length > 0 &&
                                    <div className="adv-doss-list">
                                      <ul className="list-items-cont">                                        {
                                            medicineAlternativeListAdvance.map((alternativeItem, index) => (
                                              <li key={index} className="colors-alt" onClick={() => handleCallSwapAdvancedMedication(alternativeItem)}>
                                                {alternativeItem.proprietaryName}
                                              </li>                                      
                                            ))
                                          }
                                        </ul>

                                    </div>
                                }
                            </div>
                        </div>
                     
                      </div>
                    
                      <div className="form-group">
                              <button type="submit" className="btn btn-rescure" disabled={processing}>Safe Updates</button>                             
                      </div>                      
                  
                    </form>)
                  }
                   
                </div>
                {
                  processing && (
                        <div className="img-source">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                  }
            </div>
      
               
        </div>
    </Dialog>
  )
}

export default DlgScriptEdit