import React, { useRef, useState } from 'react';
import axios from "axios";
import * as CONSTANTS from "../../CONSTANTS";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { toast } from 'react-toastify';


function DlgListPharmUserEdit({showAddPharmUsersEditUser, setShowAddPharmUsersEditUser,pharmEmail, currentUser, person}){
    const [processing, setProcessing]                            = useState(false);
    const [dislayOrEdit, setDislayOrEdit]                        = useState("showcontent"); //showediting


    const nameRef                                       = useRef();
    const surnameRef                                    = useRef();
    const phoneNumberRef                                = useRef();
    const emailUserRef                                  = useRef();
    const professionRef                                 = useRef();


    const updateUserStatus = async (status) =>{
        setProcessing(true);  
        try{

            const newUser = {
                "userID": currentUser._id, 
                "user": {
                    "approve": status
                }
            }
    
            const resUpdate = await axios.put(CONSTANTS.API_URL +"users/update/userdetails", newUser,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
            if(status == true){
                toast.success("This account has been successfully approved.");
            }else {
                toast.success("This account has been successfully disabled.");
            }
           
            setProcessing(false);  
            setShowAddPharmUsersEditUser(false);
        }catch(error){
            console.log(error);
            toast.error("Something went wrong, please try again later.");
            setProcessing(false); 
        }

    }
    async function HandleEditingPharmacyUpdate(e) {
        e.preventDefault();  
        setProcessing(true);  
        try{

            const newUser = {
                "userID": currentUser._id, 
                "user": {
                    "name": nameRef.current.value,
                    "surname": surnameRef.current.value,
                    "phone": phoneNumberRef.current.value,
                    "email": emailUserRef.current.value,
                    "profession": professionRef.current.value
                }
            }

            const resUpdate = await axios.put(CONSTANTS.API_URL +"users/update/userdetails", newUser,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
            toast.success("You successfully updated the user details.")

            setProcessing(false);  
        }catch(err){
            setProcessing(false);  
            console.log(err);
            toast.error("Something went wrong, please try again later.")
        }
    }
    
  return (
    <Dialog header="Pharmacy Users" visible={showAddPharmUsersEditUser} onHide={() => setShowAddPharmUsersEditUser(false)} style={{ width: '50vw' }}
     footer={
        <div>      
            <Button className="btn btn-rescure" label="Close" onClick={() => setShowAddPharmUsersEditUser(false)} />
        </div>
        }>
        <div className="modal-body-content pd10">            
            { 
             currentUser &&(
              <div className="flexme">
                 <div className="flexfit70 ">
                        <div className={"data-user pd10 form-display " + dislayOrEdit}>
                            <form onSubmit={HandleEditingPharmacyUpdate}>  
                                <div className="frow">       
                                        <div className="form-group">
                                            <div className="lbldesc">Name:</div>
                                            <input type="text" className="form-control wide100" ref={nameRef} defaultValue={currentUser.name} required/>
                                    </div>                      
                                    <div className="form-group">
                                            <div className="lbldesc">Surname:</div>
                                            <input type="text" className="form-control wide100" ref={surnameRef} defaultValue={currentUser.surname} required/>
                                    </div>  
                                    <div className="form-group">
                                            <div className="lbldesc">Email:</div>
                                            <input type="text" className="form-control wide100" ref={emailUserRef} defaultValue={currentUser.email} required/>
                                    </div>  
                                    <div className="form-group">
                                            <div className="lbldesc">Profession:</div>
                                            <input type="text" className="form-control wide100" ref={professionRef} defaultValue={currentUser.profession} required/>
                                    </div>  
                                    <div className="form-group">
                                            <div className="lbldesc">Phone Number:</div>
                                            <input type="text" className="form-control wide100" ref={phoneNumberRef} defaultValue={currentUser.phone} required/>
                                    </div>  
                                </div>
                                {
                                    (dislayOrEdit == "showediting") && (
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Safe Edit</button>                             
                                    </div>
                                    )
                                }
                            </form>
                                <div className="edit-rows">
                                {
                                    (dislayOrEdit == "showcontent") && (
                                        <Button       
                                            label="Edit"
                                            className="btn btn-rescure btsm1 mr-2"
                                            onClick={() => setDislayOrEdit("showediting")}
                                        />
                                    )
                                }
                                {
                                    (dislayOrEdit == "showediting") && (
                                        <Button
                                            label="Cancel"
                                            className="btn btn-rescure btsm1 mr-2"
                                            onClick={() => setDislayOrEdit("showcontent")}
                                        />
                                    )
                                }
                              </div>
                        </div>
                 </div>
                 <div className="flexfit30 fit-box">
                    <div className="pd10">                 
                        {
                            pharmEmail == person.email && (
                                <>
                                    {
                                        (currentUser.approve === true)
                                                ?
                                                <div className="acc-side">
                                                    <h5>Account Status</h5>
                                                <p> User Account has been approved.</p>
                                                <Button
                                                        label="Disable User"
                                                        className="btn btn-blue btsm1 mr-2"
                                                        onClick={() => updateUserStatus(false)}
                                                        />
                                                </div>
                                                :
                                                <div className="acc-side">
                                                    <h5>Account Status</h5>
                                                    <p>User Account is not approved, the user won't be able to log in.</p>
                                                    <Button
                                                        label="Enable User"
                                                        className="btn btn-green btsm1 mr-2"
                                                        onClick={() => updateUserStatus(true)}
                                                        />
                                                </div>
                                        } 
                                </>
                            )
                        }
                                        
                    </div>
                 </div>
              </div>
            )}
        </div>
    </Dialog>
  )
}

export default DlgListPharmUserEdit