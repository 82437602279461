import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

  const Step1 = ({ nextStep, handleChange, formData, showMedicalAids }) => (
    <div>
      <h2>Step 1 - Personal</h2>
      <div className="row rstep">
        <div className="col-md-8">
            <div className="form-data-step">
                <div className="personal-content">
                    <div className="form-group">
                        <div className="sm-label">ID Number</div>
                        <input
                            type="text"
                            placeholder="Enter client ID number/Passport Number"
                            value={formData.idNumber}
                            onChange={(e) => handleChange('idNumber', e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <div className="sm-label">Client Name</div>
                        <input
                            type="text"
                            placeholder="Enter client Name"
                            value={formData.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <div className="sm-label">Client Surname</div>
                        <input
                            type="text"
                            placeholder="Enter client Surname"
                            value={formData.surname}
                            onChange={(e) => handleChange('surname', e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <div className="sm-label">Gender</div>
                        <select
                                value={formData.gender}
                                onChange={(e) => handleChange('gender', e.target.value)}
                                className="form-control"
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                    </div>
                    <div className="form-group">
                        <div className="sm-label">Physical Address</div>
                        <input
                            type="text"
                            placeholder="Enter the Physical Address"
                            value={formData.address}
                            onChange={(e) => handleChange('address', e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="medical-aid-content">
                     <div className="form-group">                
                          <div className="md-label">
                            Medical Aid<br />
                            <label>
                              <input
                                type="radio"
                                value="Yes"
                                checked={formData.medicalAid === 'Yes'}
                                onChange={() => handleChange('medicalAid', 'Yes')}
                              />
                              <span>Yes</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="No"
                                checked={formData.medicalAid === 'No'}
                                onChange={() => handleChange('medicalAid', 'No')}
                                defaultChecked={true}
                              />
                              <span>No</span>
                            </label>
                          </div>
                            
                      </div>

                      {
                        showMedicalAids && (
                          <>
                          <div className="form-group">
                          <div className="sm-label">Medical Name</div>
                              <input
                                  type="text"
                                  placeholder="Enter the Medical Name"
                                  value={formData.medicalAidName}
                                  onChange={(e) => handleChange('medicalAidName', e.target.value)}
                                  className="form-control"
                              />
                          </div>
                          <div className="form-group">
                              <div className="sm-label">Medical NO</div>
                              <input
                                  type="text"
                                  placeholder="Enter your Medical Number"
                                  value={formData.medicalAidNumber}
                                  onChange={(e) => handleChange('medicalAidNumber', e.target.value)}
                                  className="form-control"
                              />
                          </div>
                          </>
                        )
                      }
                </div>
                <div className="row-buttons">                  
                  <button onClick={nextStep} className="btn btn-mulstep">Next</button>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="data-search-user">
            </div>
        </div>
      </div>
    </div>
  );
  
  const Step2 = ({ prevStep, nextStep, handleChange, formData, prescriptInput, setPrescriptInput, addPrescriptF, removePrescriptF, prescriptions}) => (
    <div>
      <h2>Step 2 - Prescriptions</h2>
      <div className="row rstep">
        <div className="col-md-12">            
            <div className="sect-sub">
      
                <div className="row">
                  
                <div className="col-md-6">
                    <div className="sm-label">Products</div>
                    <input
                        className="form-control txtTodo1"
                        type="text"
                        placeholder="Enter Product"
                        value={prescriptInput}
                        onChange={(e) => setPrescriptInput(e.target.value)}
                      />
                    <button onClick={addPrescriptF} className="btn btn-green btnsml3">Add Prescription</button>
                </div>
                <div className="col-md-6">
                   <ul className="list-items-cont">
                    {prescriptions.map((prescript, index) => (
                      <li key={index}>
                        <span className="litext">{prescript}</span>
                        <button onClick={() => removePrescriptF(index)} className="btn btn-close-ls">X</button>
                      </li>
                    ))}
                   </ul>
                </div>
                </div>
            </div>
            
        </div>
      </div>
      
      
      <div className="row-buttons">
        <button onClick={prevStep} className="btn btn-mulstep">Previous</button>  
        <button onClick={nextStep} className="btn btn-mulstep">Next</button>
      </div>
      
    </div>
  );
  
const Step3 = ({ prevStep, handleChange,formData, handleFileChange, processing,handleUploadScriptMaker}) => (
    <div>
      <h2>Step 3</h2>
      <div className="row rstep">
         <div className="col-md-12">
            <div className="form-group">
                <div className="sm-label">Dispensary Script Number (DSN)</div>
                <input
                    type="text"
                    
                    placeholder="Enter Original Script Number"
                    value={formData.scriptNumber}
                    onChange={(e) => handleChange('scriptNumber', e.target.value)}
                    className="form-control"
                />
            </div>
            <div className="form-group">
               <div className="sm-label">Original Script</div>
                 <input
                      type="file"
                      accept="image/jpeg,image/png,application/pdf"
                      placeholder="Enter Old Script"
                      onChange={(e) => handleFileChange(e.target.files[0])}
                      className="form-control"
                    />
            </div>
            <div className="form-group">
            <div className="sm-label">Valid Until</div>
                <input
                    type="text"
                    placeholder="Expiry Date (30 days)"
                    value={formData.expirydate}  
                    onChange={(e) => handleChange('expirydate', e.target.value)}
                    className="form-control"
                    readOnly={true}
                />
            </div>
            
         </div>
      </div>      
    
      <div className="row-buttons">
        <button onClick={prevStep}  className="btn btn-mulstep">Previous</button>
        <button onClick={() => handleUploadScriptMaker()} className="btn btn-mulstep">Submit</button>
      </div>
      {
        processing && (
              <div className="img-source">
                  <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
              </div>
          )
        }
    </div>
  );

function MultiFormScriptMake({person, setCreateClientScript}) {
    const [processing, setProcessing]             = useState();

    const [step, setStep]                         = useState(1);
    const [showMedicalAids, setShowMedicalAids]   = useState(false);
    const [formValues, setFormValues] = useState({
      name: '',
      surname: '',
      gender: 'male',
      idNumber: '',
      medicalAid:'No',
      medicalAidName: '',
      medicalAidNumber: '',
      scriptNumber: '',
      address: '',
      expirydate:'',
    });

    const [prescriptions, setPrescriptions]            = useState([]);
    const [prescriptInput, setPrescriptInput]          = useState('');
    
    useEffect(() => {
      initializingTheDate();
    },[])
    
    const initializingTheDate = () => {
      const currentDate = new Date();
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + 30);

     // const theDate = futureDate.getFullYear()+ "/"+ (futureDate.getMonth() + 1).toString().padStart(2, '0')+ "/" + futureDate.getDate().toString().padStart(2, '0');
     const theDate = futureDate.toISOString().split('T')[0];

      setFormValues({ ...formValues, expirydate: theDate });
    }

    const nextStep = () => {
        setStep(step + 1);
    };
    
    const prevStep = () => {
        setStep(step - 1);
    };
    
    const handleChange = (field, value) => {

         if(field == "medicalAid"){
           setShowMedicalAids(!showMedicalAids);           
         }
        setFormValues({ ...formValues, [field]: value });
    };


    const handleFileChange = (file) => {
      // Do something with the file, for example, log its details
      console.log('Selected file:', file);
  
      // If you want to store the file in form data, update the state
       setFormValues({ ...formValues, selectedFile: file });
    };

    // Function to add a new todo
    const addPrescriptF = () => {
      if (prescriptInput.trim() !== '') {
        setPrescriptions([...prescriptions, prescriptInput]);
        setPrescriptInput('');
      }
    };

    // Function to remove a todo
    const removePrescriptF = (index) => {
      const updatedTodos = [...prescriptions];
      updatedTodos.splice(index, 1);
      setPrescriptions(updatedTodos);
    };

    const handleUploadScriptMaker = async () => {
      setProcessing(true)
      //console.log('FORM:', formValues); // Log the data of Step 1
      //console.log('Prescritions:', prescriptions); // Log the data of Step 1
      try{
       

        var createUserNow = false;

        if(formValues.idNumber.length > 4 && formValues.name.length > 1 && formValues.surname.length > 3 ){
            if(formValues.medicalAid == "Yes" && formValues.medicalAidName.length > 2 && formValues.medicalAidNumber.length > 3 || formValues.medicalAid == "No" ){
            // setMessageErrStep2("");
              createUserNow = true;
            }else {
              toast.error("Please ensure the medical aid details have been added accurately.");
            }

            //Create the Script
              if(createUserNow){
                /////////////////
                   //prescription
                   if(prescriptions.length > 0){
                    
                         if(formValues.scriptNumber){
                            if(formValues.selectedFile){
                             
                                      const formData = new FormData();
                                        formData.append("singlescript", formValues.selectedFile);
                                        formData.append("title", formValues.surname +" "+ formValues.name.substring(0, 1) + " "+ formValues.idNumber.substring(0, 5));
                                        formData.append("pharmacycode", person.pharmacycode);  
                                        formData.append("origScriptNumber", formValues.scriptNumber);
                                        formData.append("medicalAidOption", formValues.medicalAid);
                                        formData.append("medicalAidNumber", formValues.medicalAidNumber);
                                        formData.append("medicalAidName", formValues.medicalAidName);
                                        formData.append("idNumber", formValues.idNumber);  
                                        formData.append("name", formValues.name);
                                        formData.append("surname", formValues.surname);
                                        formData.append("gender", formValues.gender);
                                        formData.append("address", formValues.address);  
                                        formData.append("expirydate", formValues.expirydate);  
                                        //formData.append("prescriptions", prescriptions);   
                                        prescriptions.forEach((prescription, index) => {
                                          formData.append(`prescriptions[${index}]`, prescription);
                                        });
                                      
                                      const resScript = await axios.post(CONSTANTS.API_URL +"scripts/form/script/upload", formData ,{
                                          headers: {
                                            'Content-Type': 'multipart/form-data',
                                              token: "Bearer "+ person.accessToken
                                          }
                                      });
    
                                      console.log(resScript.data);
    
                                      toast.success("Script has been created.");
                                      /////////////////
                                      setFormValues({
                                        name: '',
                                        surname: '',
                                        gender: 'male',
                                        idNumber: '',
                                        medicalAid:'No',
                                        medicalAidName: '',
                                        medicalAidNumber: '',
                                        scriptNumber: '',
                                        address: '',
                                      })
                                      
                                      setCreateClientScript(false);
                            }else{
                              toast.error("Please add Original Script File.");
                            }   
                         }else{
                          toast.error("Please add the Original Script Number.");
                         }                     
                  }else{
                    toast.error("Please add prescription products.");
                  }
              }    
         
        }else {
          toast.error("Please ensure that the ID Number/Passport, Name, Surname need to be filled in accurately.");
        }
        setProcessing(false);
        //console.log(dataUser);
      }catch(err){
        console.log(err);
        setProcessing(false);
      }
    }
    switch (step) {
        case 1:
          return <Step1 
                    nextStep={nextStep} 
                    handleChange={handleChange} 
                    formData={formValues} 
                    showMedicalAids={showMedicalAids}
                    />;
        case 2:
          return (
            <Step2
              prevStep={prevStep}
              nextStep={nextStep}
              handleChange={handleChange}              
              formData={formValues}
              prescriptInput={prescriptInput}
              setPrescriptInput={setPrescriptInput}
              addPrescriptF={addPrescriptF}
              removePrescriptF={removePrescriptF}
              prescriptions={prescriptions}
            />
          );
        case 3:
          return <Step3 
                    prevStep={prevStep} 
                    handleChange={handleChange} 
                    formData={formValues} 
                    handleFileChange={handleFileChange}
                    processing={processing}
                    handleUploadScriptMaker={handleUploadScriptMaker}
                  />;
        default:
          return null;
      }
}

export default MultiFormScriptMake