import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './logauth.css'
import SideImage from './../assets/prescripttest.png';
import HeaderLogo from './../assets/logoscript.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from '../reduxAuth/authSlice';
import Spinner from '../Components/Others/Spinner';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";

function Login() {

    const [isLoadingSimple, seIsLoadingSimple]                  = useState(false);
    const [turnOneNewPassword, setTurnOneNewPassword]           = useState(false);
    const [showLoginBlock, setShowLoginBlock]                   = useState(true);
    const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
    const [resetEmail, setResetEmail]                           = useState(false);
    
    const [emailCode, setEmailCode]                             = useState('');
    const [newPassword, setNewPassword]                         = useState('');
    const [confirmPassword, setConfirmPassword]                 = useState('');

    const emailRef                                      = useRef();
    const passwordRef                                   = useRef();
    const resetEmailRef                                 = useRef();

    const ConfirmNewPassRef                                      = useRef();
    const CreateNewPassRef                                       = useRef();
    const emailCodeSentRef                                       = useRef();

    const navigate                                       = useNavigate();
    const dispatch                                       = useDispatch();

    const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

    useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
            navigate('/scripts');
          }

          dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleLogin = async (e) => {
        e.preventDefault();
 
        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){
            const userData = {
                "email": emailRef.current.value,
                "password": passwordRef.current.value
              }        
              
              dispatch(login(userData));
        }else {
            toast.error("Email not valid");
        }
    }

    const handleReseting = async (e) => {
        e.preventDefault();
        seIsLoadingSimple(true);
        try{
            let emailCheckTest = isValidEmail(resetEmailRef.current.value);
            if(emailCheckTest){

                const resEmail = await axios.put(CONSTANTS.API_URL +"auth/reset/client/password/" + resetEmailRef.current.value);
                
                setResetEmail(resetEmailRef.current.value)              
                toast.success("Please check your email for the code.");
                resetEmailRef.current.value     = "";
                 
                setTurnOneNewPassword(true);
            }else {
                toast.error("Email not valid");
            }

            seIsLoadingSimple(false);
        }catch(err){
            console.log(err);
            seIsLoadingSimple(false);
            toast.error(err.response.data);
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isLoading) {
        return  <Spinner />
    }
    
    const handleForgotPassword = () => {
        setShowLoginBlock(!showLoginBlock);
    };
  
   const handleNewPasswordReset = async (e) => {
    e.preventDefault();
    seIsLoadingSimple(true);
        try{
           

            const emailObj = {
                "emailaddress" :resetEmail,
                "emailCode" :emailCode,
                "emailPassword" :newPassword, 
            }

            const res = await axios.put(CONSTANTS.API_URL +"auth/new/client/password/", emailObj);
     
            if(res.status == 200){
                toast.success("Your password has been updated. Please login.");
                setNewPassword("");
                setEmailCode("");
                setConfirmPassword("");
            }else {
                toast.error("Password could not get updated. Try again later.");
            }
            
            seIsLoadingSimple(false);
        }catch(err){
           // console.log(err);
            toast.error(err.response.data);
            seIsLoadingSimple(false);
        }
   }

   const isFormValid = () => {
        return (
            emailCode.length === 5 &&
            newPassword.length >= 6 &&
            newPassword === confirmPassword
        );
    };

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header">
                <img src={HeaderLogo} className="logo-one" alt="Rescure Script" />
                <div className="nav-section-part">
                    <Link to="/about" className="btn btn-rescure-login mgrite5">About</Link>
                    <Link to="/register" className="btn btn-rescure-login">Sign Up Now</Link>
                </div>
            </div>
            <div className="main-login-data">
                <div className="form-block">
                <div className={`frm-area ${showLoginBlock ? 'fade-in' : 'fade-out'}`}>
                                <h2 className="title-login">Pharmaceutical Login</h2>
                                <h4>Login here for your pharmacy to admin rescure scripts</h4>
                                <form encType="multipart/form-data">
                                    <div className="form-group frg">
                                        <div className="text-form">Email: </div>
                                        <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                                    </div>
                                    <div className="form-group frg">
                                        <div className="text-form">Password: </div>
                                        <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
                                    </div>
                                    <div className="form-group frg">
                                        <button className="btn btn-rescure-login btn-full ct" onClick={handleLogin}  disabled={isLoading} >Login</button>
                                    </div>
                                    <div className=" frg">
                                        
                                    </div>
                                </form>
                                    
                                
                                <p className="login-forgot-pass" onClick={handleForgotPassword}>Forgot Password</p>                   
                            </div>
                    
                  
                    {!isForgotPasswordVisible && (
                        <div className={`forgot-password-area ${!showLoginBlock ? 'fade-in' : 'fade-out'}`}>
                             <div className="form-block">
                                    <h4>Forgot Password</h4>
                                    {
                                       turnOneNewPassword ? 
                                        <>
                                             <form encType="multipart/form-data">
                                                    <div className="form-group frg">
                                                        <div className="text-form">Enter code: </div>
                                                        <input 
                                                            type="text" 
                                                            className="form-control ct-content wide100" 
                                                            ref={emailCodeSentRef} placeholder="Enter the code from the Email" 
                                                            value={emailCode}
                                                            onChange={(e) => setEmailCode(e.target.value)}
                                                            required/>
                                                    </div>
                                                    <div className="form-group frg">
                                                        <div className="text-form">New Password <sub>(6+ characters)</sub>: </div>
                                                        <input type="password" className="form-control ct-content wide100" 
                                                            ref={CreateNewPassRef} 
                                                            placeholder="Enter New Password"
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            required/>
                                                    </div>
                                                    <div className="form-group frg">
                                                        <div className="text-form">Confirm Password: </div>
                                                        <input type="password" className="form-control ct-content wide100" 
                                                            ref={ConfirmNewPassRef} 
                                                            placeholder="Enter Confirm Password"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                           required/>
                                                    </div>
                                                    <div className="form-group frg">
                                                        {
                                                            isFormValid() && (
                                                                <button className="btn btn-rescure-login btn-full ct" onClick={handleNewPasswordReset} 
                                                                disabled={isLoadingSimple} >
                                                                    Password Update
                                                                </button>
                                                            )
                                                        }
                                                        
                                                    </div>
                                            </form>
                                        </>
                                       : 
                                       <>
                                        <form encType="multipart/form-data">
                                                <div className="form-group frg">
                                                    <div className="text-form">Email Address: </div>
                                                    <input type="email" className="form-control ct-content wide100"  ref={resetEmailRef} placeholder="Enter Email" required/>
                                                </div>
                                                <div className="form-group frg">
                                                    <button className="btn btn-rescure-login btn-full ct" onClick={handleReseting}  disabled={isLoadingSimple} >Reseting</button>
                                                </div>
                                        </form>
                                       </>
                                    }
                             
                                  
                            </div>
                            <p className="login-forgot-pass" onClick={handleForgotPassword}>Login</p>    
                        </div>
                        )
                    }

                    {
                           isLoadingSimple && (
                            <img src={CONSTANTS.SMALL_PROCESSING} className="mini-processing" />
                           )
                    }
                </div>
                <div className="data-image-block">
                        <div className="base-login">
                            <img src={SideImage} className="logo-on-display" alt="Rescure Script" />
                        </div>
                </div>
            </div>
        </div>       
    </div>
  )
}

export default Login