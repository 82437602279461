import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function DlgVideoDisplay({showWatchingVideo, setShowWatchingVideo, thisVideo, person}) {

  return (
    <Dialog header="Video: " visible={showWatchingVideo} onHide={() => setShowWatchingVideo(false)} style={{ width: '60vw' }}
            footer={
            <div>      
                <Button className="btn btn-picky" label="Close" onClick={() => setShowWatchingVideo(false)} />
            </div>
            }>
        <div className="modal-body-content">
          <div className="data-item list-video">
             {
                thisVideo && (
                    <>
                     <div className="title-video pd10">
                       {thisVideo.title}
                    </div>
                    <video className="data-videoBox" width="auto" height="auto" controls autoPlay={false} muted playsInline src={thisVideo.videourl}></video>                   
                    </>
                )
             }              
              
          </div>
        </div>
    </Dialog>
  )
}

export default DlgVideoDisplay