import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHome, faHouseChimneyMedical, faSubscript, faUsers , faAdd, faBolt} from '@fortawesome/free-solid-svg-icons'
import './sidebar.css';
import sideLogoMobile from './../../assets/logoscript.png';


function Sidebar({member}) {
    const [sideClass, setSideClass] = useState("full-side");
  //console.log("Side Content");
 // console.log(member);
    
  return (
    <div className={"bs-side " + sideClass}>
        <div className="sidebarMenu">
            <h3 className="sidebarTitle logo-spark"><img src={sideLogoMobile} className="logo-on-dashboard" alt="Rescure Script" /></h3>          
            <ul>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                        <Link to="/" className="link-nav-item-side">
                        <span className="sd-icon"><FontAwesomeIcon icon={faHome} /></span> 
                        <span className="name-Item">HOME</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/scripts" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faSubscript} /></span> 
                            <span className="name-Item">Scripts</span>
                        </Link>
                    </div>
                </li>

                <li className="sidebarListItem">
                    <div className="col-item-block">
                    <Link to="/users" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faUsers} /></span> 
                            <span className="name-Item">Users</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/knowledge-base" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faBolt} /></span> 
                            <span className="name-Item">Knowledge Base</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                        <div className="col-item-block">
                        <Link to="/profile" className="link-nav-item-side">
                                <span className="sd-icon"> <FontAwesomeIcon icon={faAdd} /></span> 
                                <span className="name-Item">Profile</span>
                            </Link>
                        </div>
                </li>              
            </ul>
        </div>
    </div>
  )
}

export default Sidebar