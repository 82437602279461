import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { toast } from 'react-toastify';
import DlgExtensionScript from '../Components/Dialogue/DlgExtensionScript';
import { logout } from '../reduxAuth/authSlice';

function ScriptCreation() {
    const {user}                                                       = useSelector((state) => state.auth);

    const [processing, setProcessing]                                  = useState(false);
    const [thisScript, setThisScript]                                  = useState();
    const [showMedicalAid, setShowMedicalAid]                          = useState(false);
    const [showMedicalList, setShowMedicalList]                        = useState(false);
    const [listOfMedicalAids, setListOfMedicalAids]                    = useState(false);

    const [patientMedicalAid, setPatientMedicalAid]                    = useState("");
    const [showAddMissingMedicalAid, setShowAddMissingMedicalAid]      = useState(false);

    const [prescriptions, setPrescriptions]                            = useState([]);
    const [prescriptInput, setPrescriptInput]                          = useState('');

    const [validUntilDate, setValidUntilDate]                          = useState("");
    const [currentToken, setCurrentToken]                              = useState();

    //show submition button
    const [patientIDShow, setPatientIDShow]                            = useState(false);
    //feedback on ID error
    const [idMessagingError, setIdMessagingError]                      = useState("");
    const [processIDNumber, setProcessIDNumber]                        = useState(false);
    //repeat patient object
    const [repeatPatient, setRepeatPatient]                            = useState(null);
    const [showExtentionRepeat, setShowExtentionRepeat]                = useState(false);
    //button for extending a repeat patient
    const [showExRepButton, setShowExRepButton]                        = useState(false);

    const [currentStorePricing, setStorePricing]                                          = useState("");
    const [cashPayment, setCashPayment]                                                   = useState(true);

    const [listSeach, setListSearch]                                                      = useState([]);
    //If medical Aid has been disabled
    const [cahsOnly, setCashOnly]                                                         = useState(false);
    const [searchText, setSearchText]                                                     = useState("");
    const [examinationNote, setExaminationNote]                                           = useState("");
    const [showDialogSearch, setShowDialogueSearch]                                       = useState(false);  

    const dispatch                                                  = useDispatch();
    
    const idNumberRef                                               = useRef();
    const patientNameRef                                            = useRef();
    const patientSurNameRef                                         = useRef();
    const patientPhysicalAddressRef                                 = useRef();
    const patientGenderRef                                          = useRef();

    const medicalAidNumberRef                                       = useRef();
    const originalScriptNumberRef                                   = useRef();
    const originalScriptNumberDispensedRef                          = useRef();

    //File of old script
    const mainOldScriptRef                                          = useRef();
    
    const timeoutRef                                                = useRef(null);
  
    
    useEffect(() => {
        if(showMedicalAid === true){
            collectMedicalAidList();
            
        }else {
            setCashPayment(true);
        }

        initializingTheDate();
        collectCurrenttoken();
        collectCurrentPricing();

    },[showMedicalAid]);

    useEffect(() => {
        if(repeatPatient != null){
            setShowExRepButton(true);
        }
    },[repeatPatient])

    const collectMedicalAidList = async () => {
        try{
            setShowMedicalList(true);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/collect/medicals",
                headers: { 
                  'token': 'Bearer ' + user.accessToken
                }
              };
              
            //))))))))
            const resMedics = await axios.request(config);
                      
            setListOfMedicalAids(resMedics.data);
            setShowMedicalList(false);
            ////(())
            setPatientMedicalAid(resMedics.data[0].title);
        }catch(err){
            setShowMedicalList(false);
            console.log(err);
        }

    }

    const collectCurrentPricing = async () => {
        setProcessing(true);
        try{
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + user.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
            setStorePricing(response.data.storePrice);
            setCashOnly(response.data.cashonly);
            /*
            console.log("I have been careless");
            console.log(response.data.cashonly);
            console.log(response.data);
            */
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    const viewSelectionMedical = (event) => {
      
        const selectedValue = event.target.value;
        
        setPatientMedicalAid(selectedValue);
        if(selectedValue.length === 0){
            //show the other text field
            setShowAddMissingMedicalAid(true);
            
        }else {
            setShowAddMissingMedicalAid(false);
        }
    }
    

    // Function to remove a todo
    const removePrescriptF = (index) => {
        const updatedTodos = [...prescriptions];
        updatedTodos.splice(index, 1);
        setPrescriptions(updatedTodos);
    };

    const initializingTheDate = () => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 30);
  
        const theDate = futureDate.toISOString().split('T')[0];  
        setValidUntilDate(theDate);
    }

    const handleCreationOfScript = async (e) => {
        e.preventDefault();
        try{
            //layer 1 
            const oneCheck = checkFirstSection();           
            //layer 2
            const twoCheck = checkSecondSection();
            //layer 3
            const three = checkThirdSection();

            if(oneCheck && twoCheck && three){
                
                setProcessing(true);
        
                const formData = new FormData();
                formData.append("singlescript", mainOldScriptRef.current.files[0]);
                formData.append("title", patientSurNameRef.current.value +" "+ patientNameRef.current.value.substring(0, 1) );
                formData.append("pharmacycode", user.pharmacycode);  
                formData.append("origScriptNumber", originalScriptNumberRef.current.value);
                formData.append("origScriptNumDispensed", originalScriptNumberDispensedRef.current.value);
                formData.append("medicalAidOption", showMedicalAid ? "Yes" : "No");
                formData.append("medicalAidNumber", showMedicalAid ?  medicalAidNumberRef.current.value : "");
                formData.append("medicalAidName", patientMedicalAid);
                formData.append("idNumber", idNumberRef.current.value);  
                formData.append("name", patientNameRef.current.value);
                formData.append("surname", patientSurNameRef.current.value);
                formData.append("gender", patientGenderRef.current.value);
                formData.append("address", patientPhysicalAddressRef.current.value);  
                formData.append("expirydate", validUntilDate);  
                formData.append("payType", cashPayment ? "Levi" : "Claim");
                formData.append("charge", cashPayment ? currentStorePricing : "");
                formData.append("extraexamination", examinationNote);  
                  
                prescriptions.forEach((prescription, index) => {
                  formData.append(`prescriptions[${index}]`, prescription);
                });               
               
                const resScript = await axios.post(CONSTANTS.API_URL +"scripts/form/script/upload", formData ,{
                        headers: {
                        'Content-Type': 'multipart/form-data',
                            token: "Bearer "+ user.accessToken
                        }
                    });

                
                toast.success("Script has been created.");
                setProcessing(false);

                setTimeout(() => {
                    window.location.replace("/menuscript/" + resScript.data.script._id);
                }, 3400);
            }
        }catch(er){
            console.log(er);
            toast.error("Something went wrong. Please try again later.");
            setProcessing(false);
        }
    }

    const checkFirstSection = () => {
        var checkStatus = false;
        const identityNumberOption = isValidSouthAfricanID(idNumberRef.current.value);
        if(identityNumberOption){           
            checkStatus = true;
        }else {
            toast.error("Incorrect ID number format")
        }

        return checkStatus;
    }

    const isValidSouthAfricanID = (id) => {
        // Check if the ID is exactly 13 digits long
        if (id.length !== 13 || !/^\d{13}$/.test(id)) {
          return false;
        }
      
        // Extract date of birth and check if it forms a valid date
        const year = parseInt(id.substring(0, 2), 10);
        const month = parseInt(id.substring(2, 4), 10);
        const day = parseInt(id.substring(4, 6), 10);
        
        // Basic date validation
        if (month < 1 || month > 12 || day < 1 || day > 31) {
          return false;
        }
      
        return true;
    };

    const checkSecondSection = () => {
        var checkStatus = false;
        if(prescriptions.length > 0){
            checkStatus = true;
        }else {
            toast.error("Please add prescription schedule products.");
        }
       return checkStatus;
    }

    const checkThirdSection = () => {
        var checkStatus = false;

        if((originalScriptNumberRef.current.value.length > 4) && (originalScriptNumberDispensedRef.current.value.length > 4)){
            checkStatus = true;
        }else {
            toast.error("Please add the ORN & ORND. They cannot be less than five digits");
        }
        return checkStatus;
    }

    const handleChangeIDNum = async (event) => {
        const input = event.target.value;

        if (input.length === 13) {
            //make search first if it exists.
            
            //check id
            const checkIDValid = isValidSouthAfricanID(input);
            if(checkIDValid){
                setProcessIDNumber(true);
                setIdMessagingError("");
                try{
                    const idCheck = await axios.get(CONSTANTS.API_URL +"users/check/idnumber/"+ input,{
                            headers: {
                                token: "Bearer "+ user.accessToken
                            }
                        });    
                 
                    if(idCheck.data.validity === true){
                        //open a dialogue modal
                        setRepeatPatient(idCheck.data.patient);
                        //console.log("AAAAAAAAaaa");
                    }else {
                        setPatientIDShow(true);
                        //console.log("BBBBBBbbbbbbbb");
                        setRepeatPatient(null);
                    }
                }catch(err){
                    console.log(err);
                    setIdMessagingError("Error trying to validate the ID number.");
                }

                setProcessIDNumber(false);
                
            }else {
                setIdMessagingError("ID number is not valid.");
                toast.error("ID is not valid.");
            }
        }

        if(patientIDShow === true){
            setPatientIDShow(false);
        }
    }

    const collectCurrenttoken = async () => {
        setProcessing(true);

        try{
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + user.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
           
            setCurrentToken(response.data.medicationToken);
            setProcessing(false);
        }catch(err){
            //console.log(err);
            setProcessing(false);
            //Check if token is invalid     
            if(err.response.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.")
                setTimeout(() => {
                document.location.href("/login");
                }, 4000);
            }
        }
    }

    const handleChangeSearch = (event) => {
        const txtSearch = event.target.value;

        if(txtSearch.length > 0){
            setSearchText(txtSearch);
  
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        
            // Set a new timeout
            if (txtSearch.length > 2) {
                timeoutRef.current = setTimeout(() => {
                    executeSearchNow(txtSearch);
                }, 1000); // Adjust the delay (in milliseconds) as needed
            }

        }else {
            setSearchText("");
            setListSearch([]);
            setShowDialogueSearch(false);
        }
        
    }
  
    const executeSearchNow = async (content) =>{
        try{
          setProcessing(true);
            
            const obSearch = {
              "searching" : content
            }
            
            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_CJMED +"medicine/search/advanced/",
                headers: { 
                  'api_key': currentToken, 
                  'Content-Type': 'application/json'
                },
                data : obSearch
              };

            //))))))))
            const resData = await axios.request(config);
            //))))))))

            if(resData.data.length > 0){
                setListSearch(resData.data);
                setShowDialogueSearch(true);
            }else {
              toast.error("Nothing found");
            }

            setProcessing(false);
        }catch(err){
          setProcessing(false);
          console.log(err);
          toast.error(err.response.data);
        }
    }

    const processCurrentSearched = (searched) => {

        const stringMedication = searched.proprietaryName + " (" + searched.activeIngredient + ") " + searched.packSize + searched.dosage +" "+ searched.unit;

        setPrescriptions([...prescriptions, stringMedication]);

        //Reset ----->>>>
        setListSearch([]);
        setShowDialogueSearch(false);
        setSearchText("");
    }

    const handleExamination = (eresult) => {
        const input = eresult.target.value;
        setExaminationNote(input);
    }

    const handleCashPaymentChange = (option) => {
        setCashPayment(option);
               
    }    

  return (
    <div className="main-standard-version">
        <h2 className="lbl-title-page">Script Creation</h2>
        <div className="card">
          <div className="card-body">
          <form onSubmit={handleCreationOfScript}>  
                <div className="script-section">
                    <h4>Step 1 - Personal</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                            <div className="sm-label">ID Number*</div>
                            <input
                                type="text"
                                placeholder="Enter Patient ID Number"                         
                                ref={idNumberRef}
                                onChange={handleChangeIDNum}
                                className="form-control"
                                disabled={processIDNumber}
                                required
                            />
                            {
                                processIDNumber && (
                                    <img src={CONSTANTS.SMALL_PROCESSING} className="inn-process small-img" /> 
                                )
                            }
                           
                        </div>

                        <div className="form-group">
                            <div className="sm-label">Patient Name*</div>
                            <input
                                type="text"
                                placeholder="Enter Patient Name"                         
                                ref={patientNameRef}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <div className="sm-label">Patient Surname*</div>
                            <input
                                type="text"
                                placeholder="Enter Patient Surname"                         
                                ref={patientSurNameRef}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <div className="sm-label">Physical Address*</div>
                            <input
                                type="text"
                                placeholder="Enter Patient Physical Address"                         
                                ref={patientPhysicalAddressRef}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <div className="sm-label">Gender</div>
                            <select
                                    ref={patientGenderRef}                                
                                    className="form-control"
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                        </div>
                        {
                            cahsOnly === false && (
                                <div className="form-group">
                                        <div className="sm-label">Medical Aid</div>
                                            <div className="md-label">                           
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="Yes"
                                                        checked={showMedicalAid}                                        
                                                        onChange={() => setShowMedicalAid(true)}
                                                    />
                                                    <span>Yes</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="No"
                                                        checked={!showMedicalAid}
                                                        onChange={() => setShowMedicalAid(false)}
                                                    />
                                                    <span>No</span>
                                                </label>
                                            </div>

                                    {
                                            showMedicalAid && (
                                            <>
                                                    <div className="form-group">
                                                        <div className="sm-label">Medical Name</div>
                                                        {
                                                            showMedicalList && (
                                                                <div className="img-source">
                                                                    <img src={CONSTANTS.SMALLER_PROCESS} className="inn-process" />
                                                                </div>
                                                            )
                                                            }

                                                            {
                                                                listOfMedicalAids && (
                                                                    <select    
                                                                        onChange={viewSelectionMedical}                                                                              
                                                                        className="form-control">
                                                                    {
                                                                        listOfMedicalAids.map((medicalItem, index) => {
                                                                            return (<option value={medicalItem.title} key={index}>{medicalItem.title}</option>)
                                                                        })
                                                                    
                                                                    }
                                                                    <option value="">Other</option>
                                                                    </select>
                                                                )
                                                            }
                                                            {
                                                                showAddMissingMedicalAid && (
                                                                    <p className="dt-space mgtop10">
                                                                        <input
                                                                                type="text"
                                                                                placeholder="Enter your Medical Aid Name*"
                                                                                className="form-control"
                                                                                onChange={(e) => setPatientMedicalAid(e.target.value)}
                                                                                required
                                                                            />
                                                                    </p>
                                                                )
                                                            }
                                                        </div>
                                                    <div className="form-group">
                                                        <div className="sm-label">Medical Aid Number*</div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter your Medical Number"
                                                            ref={medicalAidNumberRef}
                                                            className="form-control"
                                                            required
                                                        />
                                                    </div>
                                            </>
                                            )
                                        }
                                    </div>
                            )
                        }
                        
                         <div className="form-group">
                            <div className="switch-figure-payment">
                               
                                {
                                    cahsOnly === false &&(
                                        <div className="pricing-buttons">
                                            <button type="button" className={"btn mgright5 selection-buttons cashm" + cashPayment} onClick={() => handleCashPaymentChange(true)}>
                                                Cash Payment {cashPayment ? "on" : "off"}
                                            </button>
                                            <button type="button" className={"btn selection-buttons cashm" + !cashPayment} onClick={() => handleCashPaymentChange(false)} disabled={!showMedicalAid}>
                                                Medical Claim {cashPayment ? "off" : "on"}
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    cashPayment && (
                                        <>
                                          {
                                            (currentStorePricing.length > 0) &&
                                                (
                                                    <div className="pricing-amount">
                                                        R {currentStorePricing}
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                                
                            </div>
                         </div>
                      </div>
                      <div className="col-md-6">
                          {
                            (idMessagingError.length > 1) && (
                                <div className="pd10">
                                    <div className="alert alert-warning">
                                        {idMessagingError}
                                    </div>
                                </div>
                            )
                          }
                          {
                            showExRepButton && 
                            repeatPatient !== null && (
                               <div className="pd10">
                                 <Link to={"/patient/" + repeatPatient.idNumber} className="btn btn-rescure">View Script</Link>
                               </div>
                            )
                          }
                        <div className="fill-in-side">
                           <p>Fill in the nesessary fields please.</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="script-section">
                    <div className="row">
                      <div className="col-md-6">
                      <h4>Step 2 - Expired Script Information</h4>
                           <div className="form-group">
                                <div className="sm-label">Original Script Number (ORN)*</div>
                                <input
                                    type="text"                                
                                    placeholder="Enter Original Script Number"
                                    ref={originalScriptNumberRef}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <div className="sm-label">Original Script Number Dispensed (ORND)*</div>
                                <input
                                    type="text"                                
                                    placeholder="Enter Expired Dispensed Script Number"
                                    ref={originalScriptNumberDispensedRef}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                
                                <div className="sm-label">Original Script Upload*</div>
                                    <input
                                        type="file"
                                        accept="image/jpeg,image/png,application/pdf"
                                        placeholder="Enter Old Script Document"
                                        ref={mainOldScriptRef}
                                        className="form-control"
                                        required
                                        />
                                </div>
                            <div className="form-group">
                                 <div className="sm-label">Valid Until</div>
                                <input
                                    type="text"
                                    placeholder="Expiry Date (30 days)"
                                    value={validUntilDate}  
                                    className="form-control"
                                    readOnly={true}
                                />
                            </div>
                      </div>
                      <div className="col-md-6">
                            <div className="fill-in-side">
                                    <p className="info-text">
                                        The <strong>ORN</strong> would be the Rx number in independent pharmacy systems.
                                    </p>
                                    <p className="thin">Add note</p>
                            </div>
                      </div>
                    </div>
                </div>
                <div className="script-section">
                    <div className="row">
                        <div className="col-md-7">
                            <h4>Step 3 - Medication</h4>
                            <div className="search-box">
                                <input 
                                    type="text"
                                    className="searchMain"
                                    onChange={handleChangeSearch}
                                    placeholder="Add Search text, 2 characters or more..."
                                    value={searchText}
                                    />
                            </div>

                            {
                                showDialogSearch && (
                                    <div className="dialog-search">
                                        {
                                            listSeach.map((searched, index) => {
                                            return <div className="content-search" key={index} onClick={() => processCurrentSearched(searched)}> 
                                                    <strong>{searched.proprietaryName}</strong> 
                                                    <span className="mgright5">({searched.activeIngredient}) </span> 
                                                    <span className="thin mgright5">{searched.packSize} {searched.dosage}</span> 
                                                    <span className="smaller thin">({searched.unit})</span> 
                                                </div>
                                            })        
                                        }     
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-md-5">
                            <ul className="list-items-cont">
                                {
                                    prescriptions.map((prescript, index) => (
                                        <li key={index}>
                                            <span className="litext">{prescript}</span>
                                            <button type="button" onClick={() => removePrescriptF(index)} className="btn btn-close-ls">X</button>
                                        </li>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="script-section">
                   <div className="row">
                        <div className="col-md-6">
                            <h4>Step 4 - Examination</h4> <div className="opti-one">Optional</div>
                            <div className="form-group">
                                <textarea
                                    className="form-control txtarea3"
                                    placeholder="Examination results"
                                    onChange={handleExamination}></textarea>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fill-in-side">
                                <p className="info-text">
                                    If needs be you can give the patient an examination and add findings to this field.
                                </p>
                                <p className="thin">Add note</p>
                            </div>
                      </div>
                   </div>
                </div>
                {
                    patientIDShow && (
                        <div className="pd10">
                            <button type="submit" className="btn btn-mulstep">Submit</button>
                        </div>
                    )
                }
                
                {
                    processing && (
                        <div className="img-source">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                }
    
            </form>
          </div>
          { 
                (repeatPatient != null) && (
                    <DlgExtensionScript 
                        showExtentionRepeat={showExtentionRepeat} 
                        setShowExtentionRepeat={setShowExtentionRepeat} 
                        extensionPatient={repeatPatient}      
                        person={user} 
                      />
                   )
            }
        </div>
    </div>
  )
}

export default ScriptCreation