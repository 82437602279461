import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { logout } from '../reduxAuth/authSlice';

function Home() {

  const [processing, setProcessing]                      = useState(false);
  const {user}                                           = useSelector((state) => state.auth);
  const [pharmItem, setPharmItem]                        = useState();
  const [dislayOrEdit, setDislayOrEdit]                  = useState("showcontent"); //showediting
  const [clientIdNumber, setClientIDNumber]              = useState("");
  const [showClientIDBtn, setShowClientIDBtn]            = useState(false);

  //repeat patient object
  const [repeatPatient, setRepeatPatient]                            = useState(null);
  const [showExtentionRepeat, setShowExtentionRepeat]                = useState(false);
  //button for extending a repeat patient
  const [showExRepButton, setShowExRepButton]                        = useState(false);

  const dispatch                                         = useDispatch();

  const nameRef                                = useRef();
  const streetRef                              = useRef();  
  const townRef                                = useRef();
  const cityRef                                = useRef();  
  const zipCodeRef                             = useRef();
  const phoneRef                               = useRef();


  useEffect(() => {
    getPharmData();
  },[])

  useEffect(() => {
      if(repeatPatient != null){
          setShowExRepButton(true);
      }
  },[repeatPatient])

  const getPharmData = async () => {
    setProcessing(true);
    try {
 
        const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/"+ user.pharmacycode, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        console.log(res.data);
        setPharmItem(res.data);
        setProcessing(false);
      } catch (err) {
        console.log(err);   
        setProcessing(false);
        //Check if token is invalid     
        if(err.response.status === 403){
          localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
          dispatch(logout());
          toast.error("Session has timeout out.")
          setTimeout(() => {
            document.location.href("/login");
          }, 3000);
        }
      }
    
  }

  async function HandleEditingPharmacy(e) {
    e.preventDefault();  
    setProcessing(true);   
    try{
       console.log("Editing");
      /*
        const pharmContent = {
          "name" : nameRef.current.value,
          "street" : streetRef.current.value,
          "town" : townRef.current.value,
          "city" : cityRef.current.value,
          "zipcode" : zipCodeRef.current.value,
          "pharmacycode" : pharmcode,
          "phone" : phoneRef.current.value,
        }
        setProcessing(false);

        const response = await axios.post(CONSTANTS.API_URL + "pharmacy/", pharmContent, {
              headers: {
                  token: "Bearer "+ person.accessToken
              }
          });
        console.log(response.data);
      */
       // e.target.reset();
    } catch (err){
      console.log(err);
      setProcessing(false);
   
    }       
  }

  const handleClientCheck = (content) => {
    
    if(content.target.value.length === 13){
      setClientIDNumber(content.target.value);
      setShowClientIDBtn(true);
    }else {
      setShowClientIDBtn(false);
    }
  }

  const checkInputIdClientId = async () => {
    try{
      setProcessing(true);
      const idCheck = await axios.get(CONSTANTS.API_URL +"users/check/idnumber/"+ clientIdNumber,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        console.log(idCheck);
        setProcessing(false);
        if(idCheck.data.validity === true){

          setRepeatPatient(idCheck.data.patient);
        }else {
          toast.error("Patient is not found.");
        }
      
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Home</h2>
      {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                </div>
            )
        }
      <div className="card">
        <div className="card-body">
        <h4 className="lbl-title-inner">Pharmacy</h4>
          <div className="sect-box">
            <div className={" data-full-block form-display " + dislayOrEdit}>
                <div className="form-content ">
                        {
                          pharmItem && (  
                          <form onSubmit={HandleEditingPharmacy}>  
                              <div className="row frow">                 
                                <div className="col-md-4">
                                      <div className="form-group">
                                          <div className="lbldesc">Title:</div>
                                          <input type="text" className="form-control wide100" ref={nameRef} defaultValue={pharmItem.title} required/>
                                    </div>                      
                                    <div className="form-group">
                                          <div className="lbldesc">Street:</div>
                                          <input type="text" className="form-control wide100" ref={streetRef} defaultValue={pharmItem.address.street} required/>
                                    </div>    
                                </div>                
                        
                              <div className="col-md-4">
                                  <div className="form-group">
                                        <div className="lbldesc">Town:</div>
                                        <input type="text" className="form-control wide100" ref={townRef} defaultValue={pharmItem.address.town} required/>
                                  </div>  
                                  <div className="form-group">
                                        <div className="lbldesc">City:</div>
                                        <input type="text" className="form-control wide100" ref={cityRef} defaultValue={pharmItem.address.city} />
                                  </div>
                              </div>
                              <div className="col-md-4">
                                    <div className="form-group">
                                          <div className="lbldesc">ZipCode:</div>
                                          <input type="text" className="form-control wide100" ref={zipCodeRef} defaultValue={pharmItem.address.zipcode} />                                
                                    </div>
                                    <div className="form-group">
                                          <div className="lbldesc">Phone Number:</div>
                                          <input type="text" className="form-control wide100" ref={phoneRef} defaultValue={pharmItem.phone} />
                                    </div>
                              </div>
                            </div>
                            {
                              (dislayOrEdit == "showediting") && (
                                <div className="form-group">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Safe Edit</button>                             
                              </div>
                              )
                            }
                        
                          </form>)
                        }
                    
                </div>
                <div className="edt-row">
                      {
                        (dislayOrEdit == "showcontent") && (
                          <Button       
                            label="Edit"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setDislayOrEdit("showediting")}
                          />
                        )
                      }
                      
                      {
                        (dislayOrEdit == "showediting") && (
                          <Button
                            label="Cancel"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setDislayOrEdit("showcontent")}
                        />
                        )
                      }
                        
                </div>
            </div>
            </div>
            <div className="sect-box dotted-mods">
               <div className="row">
                  <div className="col-md-6">
                    <h4>Patient</h4>
                    <p>Search for saved user</p>
                    <input type="text" className="form-control" onChange={handleClientCheck} />
                    {
                       showClientIDBtn &&(
                        <button className="btn btn-rescure btsm1 mgtop10" disabled={processing} onClick={() => checkInputIdClientId()}>
                          Search Patient
                        </button>
                       )
                    }                    
                  </div>
                  <div className="col-md-6">
                       {
                            showExRepButton &&(
                               <div className="pd10">
                                 <Link to={"/patient/" + repeatPatient.idNumber} className="btn btn-rescure">View Patient Scripts</Link>
                               </div>
                            )
                      }
                  </div>
               </div>
            </div>
        </div>
       
      </div>
    </div>
  )
}

export default Home