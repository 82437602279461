import React, { useEffect, useState } from 'react';
import HeaderLogo from './../assets/logoscript.png';
import { Link } from 'react-router-dom';
import SideImage from './../assets/prescripttest.png';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faMedkit, faHand, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons'
import FrDlgVideoDisplay from '../Components/Dialogue/FrDlgVideoDisplay';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';

function About() {
    const [tutorialVideos, setTutorialVideos]                      = useState([]);
    const [selectedVideo, setSelectedVideo]                        = useState();
    const [showWatchingVideo, setShowWatchingVideo]                = useState(false);

    useEffect(() => {
        getVissibleVideos();
    },[])

    const getVissibleVideos = async () => {
        try {
          
            const resVids = await axios.get(CONSTANTS.API_URL +"videos/list/topharm/client/front");
            setTutorialVideos(resVids.data);  
        } catch (err) {
            console.log(err);        
        }        
    }

    const viewThisVideo = (data) => {
        setSelectedVideo(data);
        setShowWatchingVideo(true)
      }
  return (
    <div className="logo-base flexlog">
         <div className="log-start">
            <div className="reg-header">
                    <img src={HeaderLogo} className="logo-one" alt="Rescure Script" />
                    <div className="nav-section-part">
                        <Link to="/login" className="btn btn-rescure-login mgrite5">Login</Link>
                        <Link to="/register" className="btn btn-rescure-login">Sign Up Now</Link>
                    </div>
            </div>
            <div className="initial-stipe">
                <div className="container">
                    <div className="row rows-shado">
                        <div className="col-md-6">
                            <div className="text-a title-text1">
                                <div className="tt1-sub-1">
                                ResQ-Scripts
                                </div>
                                <div className="tt2-sub-1">
                                There to lift up a hand in emergency situations
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-a title-text2">
                                <div className="tt2-sub">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faMedkit} /></span> 
                                </div>
                                <div className="tt2-sub-2">
                                  Ever find customers who are in need of chronic medication?
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-a title-text2">
                                <div className="tt2-sub">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faHand} /></span> 
                                </div>
                                <div className="tt2-sub-2">
                                  Now you can give them assistance in their most dier situation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="main-base-data">
               <div className="rsq-section">
                 <div className="container">
                        <div className="row">
                        <div className="col-md-6">
                            <div className="base-brand-image">
                                <img src={SideImage} className="logo-on-display" alt="Rescure Script" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <div className="content-text-sect">
                                    <h4>What We Do</h4>
                                    <div className="ct-content-text">
                                        <p>
                                        At <strong>ResQ-Scripts</strong>, we specialize in providing a seamless platform for pharmacies to efficiently extend approved medical scripts, utilizing our government-approved credentials. Our mission is to simplify and expedite the process, ensuring patients receive timely access to their necessary medications.
                                        </p>
                                        <p>We operate with full accreditation from the government, guaranteeing compliance with all regulatory standards. Pharmacies can trust our platform to adhere to the strictest guidelines.</p>
                                        <p>With our streamlined process, pharmacies can quickly and effortlessly apply for script extensions, minimizing administrative burdens and reducing wait times for patients.</p>
                                        <p>ecurity is our top priority. Rest assured, all data transmission and storage adhere to the highest encryption standards, ensuring the confidentiality and integrity of sensitive information.</p>
                                    </div>
                                </div>
                                <div className="content-text-sect">
                                  
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="rsq-section">
                    <div className="container">
                        { 
                            selectedVideo && (
                                <FrDlgVideoDisplay
                                    showWatchingVideo={showWatchingVideo} 
                                    setShowWatchingVideo={setShowWatchingVideo}
                                    thisVideo={selectedVideo}
                                />
                                )
                        }
                       <h5>Video Tutorials</h5>
                       <div className="row">
                        {
                            tutorialVideos && (
                                tutorialVideos.map((video, i) => {
                                return (
                                  <div className="col-md-4" key={i}>
                                      <div className="block-bt">
                                            <p>{video.title}</p>
                                            <button className="btn btn-rescure" onClick={() => viewThisVideo(video)}>View</button>
                                      </div>
                                   
                                </div>
                                )
                            })

                            )
                        }
                   
                    
                       </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
  )
}

export default About