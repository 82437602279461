import React from 'react';
import headerLogoImage from './../../assets/headerscr.jpg';
import footerImage from './../../assets/signature.jpg';
import mainFont from './../../fonts/OpenSansFont.ttf';
import lightFont from './../../fonts/OpenSansLight.ttf';
import boldFont from './../../fonts/OpenSansBold.ttf';


import { Document, Page, pdf, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
    family: 'OpenSans',
    fonts: [
      { src: mainFont, fontStyle: 'normal', fontWeight: 'normal' },
      { src: lightFont, fontStyle: 'normal', fontWeight: 'light' },
      { src: boldFont, fontStyle: 'normal', fontWeight: 'bold' },
    ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    marginBottom: 10,
    alignItems: 'center',
  },
  headerImage: {
    width: 500, // Adjust width as needed
  },
  subhead: {
    alignItems: 'flex-end',
  },
  txtHeader: {
    fontFamily: 'OpenSans',
    fontSize: 10, // Adjust the font size here
    fontWeight: 'light',
  },
  section: {
    margin: 10,
    padding: 10,
    paddingBottom: 20, // Additional padding at the bottom
    flexGrow: 1,
  },
  txtDate: {
    fontSize: 10, // Adjust the font size here
    paddingBottom: 3, // Additional padding at the bottom
    fontFamily: 'OpenSans',
    fontWeight: 'light',
  },
  text13: {
    fontSize: 13, // Adjust the font size here
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
  },
  text12: {
    fontSize: 12, // Adjust the font size here
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  text10: {
    fontSize: 10, // Adjust the font size here
    fontFamily: 'OpenSans',
    fontWeight: 'light',
  },
  text9:{
    fontSize: 9, // Adjust the font size here
    fontFamily: 'OpenSans',
    fontWeight: 'light',
  },
  userList: {
    marginVertical: 10,
    fontSize: 11, 
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
  },
  listItem: {
    marginBottom: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    pageBreakBefore: 'always',
  },
  footerLeft: {
    flex: 2,
    alignItems: 'flex-start',
  },
  footerRight: {
    flex: 1,
    alignItems: 'flex-end',
  },
  footerImage: {
    width: 400, // Adjust width as needed
    height: 50, // Adjust height as needed
  },
});

function Printer({ dataScriptObject}) {
    const pageCount = Array.from(new Array(1)); // A
    const password = dataScriptObject.idNumber; 
  return (
    <pdf encryption={{ userPassword: password }}>
      <Page size="A4" style={styles.page}>
        {/* Header with image */}
        <View style={styles.header}>
          <Image style={styles.headerImage} src={headerLogoImage} />
        </View>
         {/* Header code */}
         <View style={styles.subhead}>
            <Text style={styles.txtHeader}>Script Code: {dataScriptObject.scriptcode}</Text>
            <Text style={styles.txtDate}>Valid Until: { new Date(dataScriptObject.expiryDate).toLocaleDateString()}</Text>
        </View>
        {/* Main content */}
        <View style={styles.section}>           
          
          <Text style={styles.text12}>Patient Name: {dataScriptObject.fullname}</Text>
          <Text style={styles.text12}>ID Number: {dataScriptObject.idNumber}</Text>
          {
            (dataScriptObject.medical.medicalAidOption == true) && (
                <>
                    <Text style={styles.text12}>Medical Aid Number: {dataScriptObject.medical.medicalAidNumber}</Text>
                    <Text style={styles.text12}>Medical Aid Name: {dataScriptObject.medical.medicalAidName}</Text>  
                </>
              )
          }       
         
          {/* Display userList as a numbered list */}
          <View style={styles.userList}>

            {dataScriptObject.prescriptions.map((prescript, index) => (
              <Text key={index} style={styles.listItem}>
                {`${index + 1}. ${prescript}`}
              </Text>
            ))}
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerLeft}>
            <Image style={styles.footerImage} src={footerImage} />
          </View>  
          <View style={styles.footerRight}>
            <Text  style={styles.text9}> {dataScriptObject.generatedTimeStamp}</Text> 
            {
              (dataScriptObject.status.printCount > 0) && (           
                 <Text  style={styles.text9}>Extension {dataScriptObject.status.printCount + 1}</Text>       
              )
            }           
          </View>
        </View>
      </Page>
      </pdf>
  );
}

export default Printer;