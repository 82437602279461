import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useEffect } from 'react';
//Patient or client details update

function DlgClientDetailsUpdate({showClientDetailsUpdate, setShowClientDetailsUpdate, patientDetails, person}){

    const [processing, setProcessing]                                           = useState(false);

    const [showListOfMedicalAidsForUpdate, setShowListOfMedicalAidsForUpdate]   = useState(false);
    const [listOfMedicalAids, setListOfMedicalAids]                             = useState(false);
    const [medicalAidName, setMedicalAidName]                                   = useState(patientDetails.medical.medicalAidName);

    const addressRef                                               = useRef();
    const medicalAidNumberRef                                      = useRef();
    //const medicalAidNameRef                                      = useRef();

    useEffect(() => {
        if(showListOfMedicalAidsForUpdate){
            collectMedicalAidList();
        }
    },[showListOfMedicalAidsForUpdate])

    const collectMedicalAidList = async () => {
        try{
            setProcessing(true);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/collect/medicals",
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                }
              };
              
            //))))))))
            const resMedics = await axios.request(config);                      
            setListOfMedicalAids(resMedics.data);
            setProcessing(false);

        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    const viewSelectionMedical = (event) => {      
        const selectedValue = event.target.value;        
        setMedicalAidName(selectedValue);
    }

    const handleMedicalAidName = async (event) => {
        const input = event.target.value;
        setMedicalAidName(input);
    }

    async function handleClientUpdates(e) {
        e.preventDefault();  
        setProcessing(true);  
        try{
        
            setProcessing(false);              
            
            //Check if medical Aid exists or not
            if((medicalAidName.length === 0 && medicalAidNumberRef.current.value.length === 0) ||
               (medicalAidName.length > 0  && medicalAidNumberRef.current.value.length > 0)){
                             
                var medicalAidStatus = true;

                if(medicalAidName === "" && medicalAidNumberRef.current.value === ""){
                    medicalAidStatus = false;
                }

                //Init
                    var medicalArray = {
                        "medicalAidOption" : medicalAidStatus,
                        "medicalAidNumber": medicalAidNumberRef.current.value,
                        "medicalAidName": medicalAidName,
                    } 
                    
                    //////////////
                    var mainUpdate = {
                    "user": {
                        "medical": medicalArray,
                        "address": addressRef.current.value,
                    },
                    "userid" : patientDetails._id
                    }
                
                                    
                    const resDetails = await axios.put(CONSTANTS.API_URL +"users/update/client/details", mainUpdate,{
                            headers: {
                                token: "Bearer "+ person.accessToken
                            }
                        });
                        
                        
                        setProcessing(false);  
                        //if giveEditReason then delete the current pdf in order to prepare for a new one.
                        //update viewing of download as well.
                        toast.success("Update has been completed.");
                        setTimeout(function () {
                            window.location.reload(true);
                        }, 3000);
                    
            }else{
                setProcessing(false);  
               toast.error("Please add relevant medical aid records if they exist.")
            }
            
        }catch(err){
            setProcessing(false);  
            console.log(err);
            toast.error("Something went wrong, please try again later.")
        }
    }

  return (
    <Dialog header="Update Patient Details" visible={showClientDetailsUpdate} onHide={() => setShowClientDetailsUpdate(false)} style={{ width: '65vw' }}
          footer={
            <div>      
                <Button className="btn btn-rescure" label="Close" onClick={() => setShowClientDetailsUpdate(false)} />
            </div>
            }>
        <div className="modal-body-content">
            
            <form onSubmit={handleClientUpdates}> 
                <div className="form-group">
                    <div className="lbl-field">Address</div>                    
                    <input type="text" className="form-control wide100" ref={addressRef} defaultValue={patientDetails.address} required/>
                </div>
                
                <div className="form-group">
                    <div className="flexme">
                        <div className="flexfit50">
                            <div className="form-group ptlr">
                                <div className="lbl-field">Medical Aid Name</div>                    
                                <input 
                                    type="text" 
                                    className="form-control wide100" 
                                    onChange={handleMedicalAidName} 
                                    value={medicalAidName}
                                    />
                            </div>
                            <div className="form-group">
                                <button 
                                    type="button" 
                                    className="btn btn-green btsm1" 
                                    onClick={() => setShowListOfMedicalAidsForUpdate(true)}>Choose a Medical Aid Name
                                </button>

                                {
                                    listOfMedicalAids && (
                                       <div className="mgtop10">
                                         <select    
                                            onChange={viewSelectionMedical}                                                                              
                                                className="form-control">
                                            {
                                                    listOfMedicalAids.map((medicalItem, index) => {
                                                    return (<option value={medicalItem.title} key={index}>{medicalItem.title}</option>)
                                                })
                                                
                                            }
                                                <option value="">Other</option>
                                            </select>
                                       </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flexfit50">
                            <div className="form-group pd10">
                                <div className="lbl-field">Medical Aid Number</div>                    
                                <input type="text" className="form-control wide100" ref={medicalAidNumberRef} defaultValue={patientDetails.medical.medicalAidNumber} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                   <button  className="btn btn-rescure" disabled={processing}>Safe Updates</button> 
                </div>
            </form>
            {
                processing && (
                <div className="img-source">
                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                </div>
                )
            }
        </div>
    </Dialog>
  )
}

export default DlgClientDetailsUpdate