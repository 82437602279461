
export const API_URL = "https://rescurescripts-51d8d1272bab.herokuapp.com/api/";
export const API_BASE = "https://rescurescripts-51d8d1272bab.herokuapp.com/";
export const SESSION_COOKIE = "userXRxeqsStandard";
export const API_CJMED = "https://cjmedilus-47c38d92aa3d.herokuapp.com/api/";

/*
export const API_URL = "http://localhost:1211/api/";
export const API_BASE = "http://localhost:1211/";
export const SESSION_COOKIE = "userXRxeqsLocal";
*/
export const SMALLER_PROCESS = "https://cjmarketing.co/image_directory/loading.gif";
export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
