//import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
  import Register from "./Pages/Register";
  import Login from "./Pages/Login";
  import Dashboard from "./Pages/Dashboard";
  import {ToastContainer} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css'
  import Home from "./Pages/Home";
  import UsersList from "./Pages/UsersList";
  import PharmacyDetails from "./Pages/PharmacyDetails";
  import Profile from "./Pages/Profile";
  import Knowledge from "./Pages/Knowledge";
  import Scripts from "./Pages/Scripts";
  import ScriptDetails from "./Pages/ScriptDetails";
  import About from "./Pages/About";
import ScriptCreation from "./Pages/ScriptCreation";
import ClientDetails from "./Pages/ClientDetails";
import ChatPage from "./Pages/ChatPage";
  
  function App() {
    return (
      <>
       <Router>
          <div className="main-container">
            <Routes>              
               <Route path="/login" element={<Login />}/>  
               <Route path="/register" element={<Register />}/>
               <Route path="/about" element={<About />}/>  
               <Route element={<Dashboard />}>
                   <Route path="/" element={<Home/>}/> 
                   <Route path="/dashboard" element={<Home/>}/> 
                   <Route path="/users" element={<UsersList/>}/> 
                   <Route path="/scripts" element={<Scripts/>}/> 
                   <Route path="/menuscript/:id" element={<ScriptDetails/>}/>
                   <Route path="/create-script" element={<ScriptCreation/>}/>  
                   <Route path="/profile" element={<Profile/>}/> 
                   <Route path="/knowledge-base" element={<Knowledge/>}/> 
                   <Route path="/patient/:id" element={<ClientDetails /> } />
                   <Route path="/chat/:id" element={<ChatPage /> } />
               </Route>
            </Routes>
          </div>
       </Router>
       <ToastContainer />
      </>
    );
  }
  
  export default App;
  