import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import DlgListPharmUsers from '../Components/Dialogue/DlgListPharmUsers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import DlgListPharmUserEdit from '../Components/Dialogue/DlgListPharmUserEdit';
import { toast } from 'react-toastify';
import { logout } from '../reduxAuth/authSlice';

function UsersList() {
  const {user}                                                       = useSelector((state) => state.auth);

  const [pharmUsers, setPharmUsers]                                  = useState([]);
  const [myPharmUser, setMyPharmUser]                                = useState();
  const [showAddPharmUsers, setShowAddPharmUsers]                    = useState(false);
  const [globalFilter, setGlobalFilter]                              = useState('');
  const [selectedPharmUser, setSelectedPharmUser]                    = useState(null);
  const [popUpUser, setPopUpUser]                                    = useState();
  const [showAddPharmUsersEditUser, setShowAddPharmUsersEditUser]    = useState();

  const dispatch                                                           = useDispatch();

  useEffect(() => {
    getPharmUsers();
  },[pharmUsers])

    //////////___
    const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search User..." />
        </span>
      </div>
    );
  //////////___
  //////////___
  const linkPharmacyUserTemplate = (rowData) => {
    return (myPharmUser.email == rowData.email ) ? '' : <Button label=" Edit " className="btn btn-rescure btsm1" onClick={() => udpateCurrentUserEdit(rowData)} />
  }
  //////////___

  const udpateCurrentUserEdit = (rowData) => {
      //console.log(rowData);
      setPopUpUser(rowData);
      setShowAddPharmUsersEditUser(true);
  }
  const getPharmUsers = async () => {

    try {
 
      //console.log(user);
        const res = await axios.get(CONSTANTS.API_URL +"users/pharmcode/members/vpharm/"+ user.pharmacycode, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

       // console.log(res.data.users);
        setPharmUsers(res.data.users);
        setMyPharmUser(res.data.pharm);
        
      } catch (err) {
        console.log("Habits")
          console.log(err);  
          //Check if token is invalid     
          if(err.response.status === 403){
            localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
            dispatch(logout);
            toast.error("Session has timeout out.")
            setTimeout(() => {
              document.location.href("/login");
            }, 4000);
          }      
      }    
  }

  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Users</h2>
      <div className="card">
        <div className="card-body">

        <div className="row-box">
          
           <DlgListPharmUsers 
                  showAddPharmUsers={showAddPharmUsers}
                  setShowAddPharmUsers={setShowAddPharmUsers}
                  person={user} 
                />

              <Button
                    label=" + "
                    className="btn btn-rescure btncircle"
                    onClick={() => setShowAddPharmUsers(true)}
                    />
              {
                myPharmUser && (
                  <DlgListPharmUserEdit
                    showAddPharmUsersEditUser={showAddPharmUsersEditUser}
                    setShowAddPharmUsersEditUser={setShowAddPharmUsersEditUser}
                    pharmEmail={myPharmUser.email}
                    currentUser={popUpUser}
                    person={user} 
                  />
                )
              }
            
          </div>
          <div className="data-full-block hm-display ">
              {
                pharmUsers && (
                  <DataTable
                        value={pharmUsers}
                        header={header}
                        className="min-w-full"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pharmacy"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedPharmUser}
                        onSelectionChange={e => setSelectedPharmUser(e.value)}
                      >                      
                        <Column field="name" header="Name"></Column>
                        <Column field="surname" header="Surname"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="profession" header="profession"></Column>  
                        <Column field="approve" header="Approve"></Column>              
                        <Column header="View" body={linkPharmacyUserTemplate}></Column>                     
                    </DataTable>
                )
              }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsersList