import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import MultiFormScriptMake from '../Others/MultiFormScriptMake';


  
function DlgCreateScript({createClientScript, setCreateClientScript, person}){

    const [processing, setProcessing]                            = useState(false);

   
  return (
    <Dialog header="Create Script" visible={createClientScript} onHide={() => setCreateClientScript(false)} style={{ width: '65vw' }}
      footer={
            <div>      
                <Button className="btn btn-rescure" label="Close" onClick={() => setCreateClientScript(false)} />
            </div>
            }>
        <div className="modal-body-content">
                {
                    <MultiFormScriptMake person={person} setCreateClientScript={setCreateClientScript}/>
                }
        </div>
    </Dialog>
  )
}

export default DlgCreateScript