import React, { useRef, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


function DlgListPharmUsers({showAddPharmUsers, setShowAddPharmUsers, person}){
    const [processing, setProcessing]                            = useState(false);

    const nameRef                                       = useRef();
    const surnameRef                                    = useRef();
    const phoneNumberRef                                = useRef();
    const emailUserRef                                  = useRef();
    const professionRef                                 = useRef();
    const passwordRef                                   = useRef();


    async function handleAddUserForHere(e) {
        e.preventDefault();   
        setProcessing(true);   

        try{

            const newUser = {
                "name": nameRef.current.value,
                "surname": surnameRef.current.value,
                "phone": phoneNumberRef.current.value,
                "email": emailUserRef.current.value,
                "profession": professionRef.current.value,
                "password": passwordRef.current.value,
                "pharmacycode": person.pharmacycode
            }

            console.log(newUser);
            //console.log(person.practicenumber);

            const res = await axios.post(CONSTANTS.API_URL +"users/pharamacy/user/added", newUser,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
    
            console.log(res.data);
            //setPharmUsers(res.data);

            setProcessing(false); 

            nameRef.current.value           = "";
            surnameRef.current.value        = "";
            emailUserRef.current.value      = "";
            professionRef.current.value     = "";
            phoneNumberRef.current.value    = "";

        }catch(err){
            setProcessing(false); 
            console.log(err);
        }
    }
  return (
     <Dialog header="Pharmacy Users" visible={showAddPharmUsers} onHide={() => setShowAddPharmUsers(false)} style={{ width: '50vw' }}
     footer={
        <div>      
            <Button className="btn btn-rescure" label="Close" onClick={() => setShowAddPharmUsers(false)} />
        </div>
        }>
        <div className="modal-body-content">
            <div className="tok-info">
                <div className="form-blocks ">
                   <form onSubmit={handleAddUserForHere}>
                      <div className="row rmid">
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Name*: </div>
                                <input type="text" ref={nameRef} className="form-control w80" required/>
                            </div> 
                        </div>
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Surname*: </div>
                                <input type="text" ref={surnameRef} className="form-control w80" required/>
                            </div> 
                        </div>                        
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Email*: </div>
                                <input type="email" ref={emailUserRef} className="form-control w80" required/>
                            </div> 
                        </div>
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Job Title*: </div>
                                <input type="text" ref={professionRef} className="form-control w80" required/>
                            </div> 
                        </div>
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Password*: </div>
                                <input type="text" ref={passwordRef} className="form-control w80" defaultValue="PASSWORD1" required/>
                            </div> 
                        </div>
                        <div className="col-md-6">
                             <div className="form-group">
                                <div className="lbldesc">Phone Number: </div>
                                <input type="number" ref={phoneNumberRef}  maxLength={10} className="form-control w80"/>
                            </div> 
                        </div>
                       
                            <div className="form-group">
                                <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>
                            </div>
                      </div>  
                   </form>
                </div>
            </div>
           {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                    </div>
                )
           }
        </div>
     </Dialog>
  )
}

export default DlgListPharmUsers