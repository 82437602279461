import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgExtensionScript({showExtentionRepeat, setShowExtentionRepeat, extensionPatient, person}) {

    const [processing, setProcessing]                                   = useState(false);
    const [scriptItem, setScriptItem]                                   = useState({});
    const [pharmacyItem, setPharmacyItem]                               = useState({});
 
    const displayScriptCode = async (scrCode) => {
        try{
            setProcessing(true);
            console.log(scrCode);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "scripts/details/code/" + scrCode,
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                }
              };
              
            const response = await axios.request(config);
                
            setScriptItem(response.data.script);
            setPharmacyItem(response.data.pharmacy);
            
            console.log(response);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
            toast.error("Something went wrong, please try again later.");
        }
    }
   
  return (
    <Dialog header="Script Extension Details" visible={showExtentionRepeat} onHide={() => setShowExtentionRepeat(false)} style={{ width: '75vw' }}
      footer={
            <div>      
                <Button className="btn btn-rescure" label="Close" onClick={() => setShowExtentionRepeat(false)} />
            </div>
            }>
        <div className="modal-body-content">
               <div className="form-section-block">
                  
                        {
                            extensionPatient && (
                                <div className="extend-block">
                                  
                                    <div className="row ext-row">
                                       <div className="col-md-4">
                                           <h4 className="small-text">Details</h4>
                                           <div className="sus-block">
                                           {extensionPatient.name} {extensionPatient.surname}, {extensionPatient.gender}
                                           </div>
                                       </div>
                                       <div className="col-md-4">
                                          <h4 className="small-text">Address</h4>
                                          <div className="sus-block">
                                          {extensionPatient.address}
                                          </div>
                                       </div>
                                       <div className="col-md-4">
                                          <h4 className="small-text">Medical Aid</h4>
                                          {
                                            (extensionPatient.medical.medicalAidOption === true ) && (
                                                <div className="sus-block">
                                                    {extensionPatient.medical.medicalAidName}, {extensionPatient.medical.medicalAidNumber}
                                                </div>
                                            )
                                          }
                                       </div>
                                    </div>
                                </div>
                            )
                        }
                        
                        {
                            extensionPatient && (
                                <div className="extend-block">
                                    <h4 className="small-text">Script Codes:</h4>
                                    <div className="row ext-row">                                      
                                    
                                         {extensionPatient.scriptCodes.map((code, index) => (
                                            <div className="col-md-4" key={index} onClick={() => displayScriptCode(code)}>
                                                <div className="tab-code"> {code}</div>
                                            </div>
                                         ))}
                                         
                                    </div>
                                </div>
                            )
                        }

                        {
                            (scriptItem != null )&& (
                                <div className="extend-block">
                                    <h4 className="small-text">View</h4>
                                    <div className="row ext-row"> 
                                       <table className="table">
                                         <tbody>
                                            <tr>
                                                <td>
                                                    ORN:
                                                </td>
                                                <td>
                                                    {scriptItem?.origScriptNumber} 
                                                </td>
                                                <td>

                                                </td>
                                                <td>
                                                    ORND:
                                                </td>
                                                <td>
                                                    {scriptItem?.origScriptNumDispensed} 
                                                </td>
                                            </tr>
                                            {
                                                (scriptItem?.medical?.medicalAidOption === true) && (
                                                    <tr>
                                                        <td>
                                                            Medical Aid Name:
                                                        </td>
                                                        <td>
                                                            {scriptItem?.medical?.medicalAidName} 
                                                        </td> 
                                                        <td></td>
                                                        <td>
                                                            Medical Aid Number:
                                                        </td>
                                                        <td>
                                                            {scriptItem?.medical?.medicalAidNumber} 
                                                        </td>
                                                    </tr>   
                                                )
                                            }
                                            <tr>
                                                <td>
                                                    Version Code:
                                                </td>
                                                <td>
                                                    {scriptItem?.versionCode} 
                                                </td>
                                                <td>

                                                </td>
                                                <td>
                                                    Version:
                                                </td>
                                                <td>
                                                    {scriptItem?.version} 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Status:
                                                </td>
                                                <td>
                                                    {scriptItem?.status?.text} 
                                                </td>
                                                <td>

                                                </td>
                                                <td>
                                                    Status Message:
                                                </td>
                                                <td>
                                                    {scriptItem?.status?.textMessage} 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Download:
                                                </td>
                                                <td>
                                                    {scriptItem?.status?.printCount} 
                                                </td>
                                                <td>

                                                </td>
                                                <td>
                                                    Valid Until:
                                                </td>
                                                <td>
                                                   {new Date(scriptItem?.expiryDate).toLocaleDateString(undefined, {
                                                              year: 'numeric',
                                                              month: 'long',
                                                              day: 'numeric',
                                                              })
                                                    } 
                                                </td>
                                            </tr>
                                            {
                                                (pharmacyItem != null) && (
                                                <>
                                                    <tr>
                                                        <td>
                                                            Pharmacy:
                                                        </td>
                                                        <td>
                                                           {pharmacyItem?.title} 
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            Province:
                                                        </td>
                                                        <td>
                                                           {pharmacyItem?.province} 
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Class:
                                                        </td>
                                                        <td>
                                                           {pharmacyItem?.groupdetail} 
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            Group:
                                                        </td>
                                                        <td>
                                                           {pharmacyItem?.grouptype} 
                                                        </td>
                                                    </tr>
                                                </>
                                                )
                                            }
                                         </tbody>
                                       </table>
                                    </div>
                                </div>
                            )
                        }
                    
               </div>
               {
                        processing && (
                            <div className="img-source">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                            </div>
                        )
                }
        </div>
    </Dialog>
  )
}

export default DlgExtensionScript