import React, { useEffect, useRef, useState } from 'react';
import './logauth.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import { register, reset} from '../reduxAuth/authSlice';
import backImage01 from './../assets/img1_personal.jpg';
import backImage02 from './../assets/img2_pharm.jpg';
import backImage03 from './../assets/img3_pharmacy.jpg';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faEye, faEyeLowVision} from '@fortawesome/free-solid-svg-icons'
import Spinner from '../Components/Others/Spinner';

function Register() {

  const [step, setStep]                                           = useState(1);
  const [userArrayData, setUserArrayData]                         = useState();
  const [type, setType]                                           = useState('INDEPENDENT');
  const [franchiseDetails, setFranchiseDetails]                   = useState('');
  const [corporateDetails, setCorporateDetails]                   = useState('');

  const [pharmacyArrayData, setPharmacyArrayData]                 = useState();
  const [passWordToggle, setPasswordToggle]                       = useState(false);
  const [messagePopBack, setMessagePopBack]                       = useState("");

  const userSectionRef = useRef(null);
  const pharmacySectionRef = useRef(null);

  const emailRef                                      = useRef();
  const nameRef                                       = useRef();
  const surnameRef                                    = useRef();
  const phonenumberRef                                = useRef();
  const titleRef                                      = useRef();
  const practiceNumberRef                             = useRef();
  const passwordRef                                   = useRef();

  const streetRef                                 = useRef();
  const townRef                                   = useRef();
  const cityRef                                   = useRef();
  const zipCodeyRef                               = useRef();
  const provinceRef                               = useRef();

  const dispatch                                       = useDispatch();
  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  useEffect(() => {
    if(isError){
      ///console.log("Better Breather");
      //console.log(isError);
      toast.error(message)
    }

    if(isSuccess){
      toast.success(message)
      resetInputsNow();
    }

    dispatch(reset())
  },[user, isError, isSuccess, message, dispatch])

  const resetInputsNow = () => {
    /*
    emailRef.current.value                = "";
    surnameRef.current.value              = "";
    nameRef.current.value                 = "";
    phonenumberRef.current.value          = "";
    */
   
    titleRef.current.value                = "";
    streetRef.current.value               = "";
    townRef.current.value                 = "";
    cityRef.current.value                 = "";
  }
  
  const handleRegistration = async (e) => {
    e.preventDefault();

    var moveToNext = false;
   
      var userData3 = {
        "street": streetRef.current.value,
        "town": townRef.current.value,
        "city": cityRef.current.value,
        "zipcode": zipCodeyRef.current.value,
        "province": provinceRef.current.value
      }

      if(streetRef.current.value.length > 1){
        if(townRef.current.value.length > 1){
          if(cityRef.current.value.length > 1){
            if(zipCodeyRef.current.value.length > 1){
              if(provinceRef.current.value.length > 3){
                userData3 = {
                  "pharmtitle": titleRef.current.value,
                  "street": streetRef.current.value,
                  "town": townRef.current.value,
                  "city": cityRef.current.value,
                  "zipcode": zipCodeyRef.current.value,
                  "province": provinceRef.current.value
                }
                setPharmacyArrayData(userData3);
                moveToNext = true;
              }
            }
          }
        }
      }
      

      if(moveToNext){
        //console.log("Has not yet updated");
        //console.log(pharmacyArrayData);

        //console.log(userArrayData);
        //console.log(userData3);
        //full data
        const fullData = {
          "name": userArrayData.name,
          "surname": userArrayData.surname,
          "email": userArrayData.email,
          "phonenumber": userArrayData.phonenumber,
          "practicenumber": userArrayData.practicenumber,
          "grouptype" : userArrayData.grouptype,
          "groupdetail": userArrayData.groupdetail,
          "password" : userArrayData.password,
          "pharmtitle": userData3.pharmtitle,
          "street": userData3.street,
          "town": userData3.town,
          "city": userData3.city,
          "zipcode": userData3.zipcode,
          "province": userData3.province
        }
        //console.log(fullData);

        dispatch(register(fullData));
      }else{
        //toast.error("Please ensure all inputs for this section have been added correctly.")
      }
      //dispatch(register(userData));
  }

  function isValidEmail(email){
    return /\S+@\S+\.\S+/.test(email);
  }

  const prevStep = () => {
    setStep(step - 1);
  };

  const togglePasswordSetup = () => {    
    setPasswordToggle(!passWordToggle);
  }

 //Transitions
  const nextStep = () => {
    var foundFields = checkIfFieldsHaveBeenAdded();
    if(foundFields){
      
      if(type === "INDEPENDENT"){
        setStep(step + 1);
      }else if((franchiseDetails.length > 0) || (corporateDetails.length > 0)) {
        setStep(step + 1);
      }else {
        toast.error("Please update the group field.");
      }      
    }else {
      toast.error("Please ensure all inputs for this section have been added correctly.");   
    }  
  };


  function checkIfFieldsHaveBeenAdded(){

    var moveToNext = false;
    if(step === 1){
        if(nameRef.current.value.length > 1){
          if(surnameRef.current.value.length > 1){
            if(isValidEmail(emailRef.current.value)){
              if(phonenumberRef.current.value.length == 10){
                if(practiceNumberRef.current.value.length > 1){
                  if(passwordRef.current.value.length > 2){

                    //console.log("((((()))))");
                    //console.log("grouptype: " + type);
                    const groupString = franchiseDetails + corporateDetails;
                    //console.log(groupString);

                    const userData1 = {
                      "name": nameRef.current.value,
                      "surname": surnameRef.current.value,
                      "email": emailRef.current.value,
                      "phonenumber": phonenumberRef.current.value,
                      "practicenumber": practiceNumberRef.current.value,
                      "grouptype" : type,
                      "groupdetail": groupString,
                      "password": passwordRef.current.value
                    }
                    setUserArrayData(userData1);
                    moveToNext = true;
                  }
                }
              }
            }
          }
        }
    }
     
    return moveToNext;
  };

  if (isLoading) {
    return  <Spinner />
  }

  return (
    <div className="logo-base flexlog">
      <div className="log-start">
               <div className="head-right-host-reg">
                   <Link to="/login" className="btn btn-rescure-login">Log In</Link>
                </div>
                <CSSTransition
                  in={step === 1}
                  timeout={500}
                  classNames="fade"
                  unmountOnExit
                  nodeRef={userSectionRef}
                >
                <div className="step" ref={userSectionRef}>
                    <div className="flexregister">           
                        <div className="reg-left-image" style={{backgroundImage: `url(${backImage01})`}}>

                        </div>
                        <div className="reg-right-form-data">             
                          <div className="reg-main">
                              <div className="reg-form">
                                       <h3>Pharmacy Owner Information</h3>
                                      <div className="form-group frg">
                                          <div className="text-form">Name: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={nameRef}  placeholder="Enter Pharmacy Owner Name" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Surname: </div>
                                          <input type="text" className="form-control ct-content wide100"  ref={surnameRef} placeholder="Enter Pharmacy Owner Surname" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Email: </div>
                                          <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Pharmacy Owner Email Address" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Phone Number: </div>
                                          <input type="text" maxLength={10} className="form-control ct-content wide100" ref={phonenumberRef}  placeholder="Phone Pharmacy Owner Number" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Practice Licence: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={practiceNumberRef} placeholder="Enter The Pracitice Licences/RAMS" required/>
                                      </div>
                                      <div className="form-group frg prel">
                                          <div className="text-form">Password: </div>
                                          <input type={passWordToggle ? "text": "password"} className="form-control ct-content wide100" ref={passwordRef} placeholder="Enter Password" required/>
                                           <div className="password-eye">
                                              <button className="no-style-button1" onClick={() => togglePasswordSetup()}> 
                                                 {
                                                  passWordToggle ? <span className="view-icont"><FontAwesomeIcon icon={faEye} /></span>   : <span className="view-icont"><FontAwesomeIcon icon={faEyeLowVision} /></span>  
                                                 }
                                               
                                              </button>
                                           </div>
                                      </div>
                                      <div className="form-group frg">
                                         <div className="text-form">Group: </div>
                                          <label>
                                            <input
                                              type="radio"
                                              name="type"
                                              value="INDEPENDENT"
                                              checked={type === 'INDEPENDENT'}
                                              onChange={() => setType('INDEPENDENT')}
                                            />
                                            <span className="radItemPack">Independent</span>
                                          </label>
                                          <label>
                                            <input
                                              type="radio"
                                              name="type"
                                              value="FRANCHISE"
                                              checked={type === 'FRANCHISE'}
                                              onChange={() => setType('FRANCHISE')}
                                            />
                                            <span className="radItemPack">Franchise</span>
                                          </label>    
                                          <label>
                                            <input
                                              type="radio"
                                              name="type"
                                              value="CORPORATE"
                                              checked={type === 'CORPORATE'}
                                              onChange={() => setType('CORPORATE')}
                                            />
                                            <span className="radItemPack">Corporate</span>
                                          </label>    
                                          <div className="in-block">
                                          {
                                            type === 'FRANCHISE' && (
                                                <div>
                                                      <input
                                                        id="franchiseDetails"
                                                        type="text"
                                                        value={franchiseDetails}
                                                        onChange={(e) => setFranchiseDetails(e.target.value)}
                                                        placeholder="Speficy The Franchise"
                                                        className="form-control ct-content wide100" 
                                                      />
                                                  </div>
                                                )
                                              }
                                              {
                                                type === 'CORPORATE' && (
                                                  <div>
                                                        <input
                                                          id="corporateDetails"
                                                          type="text"
                                                          value={corporateDetails}
                                                          onChange={(e) => setCorporateDetails(e.target.value)}
                                                          placeholder="Speficy The Corporate"
                                                          className="form-control ct-content wide100" 
                                                        />
                                                  </div>
                                                  )                                                
                                              }
                                            </div>                                      
                                       </div>
                                  

                                      <div className=" frg">
                                          
                                      </div>
                      
                                  </div>                                 
                          </div>
                        </div>
                    </div>

                    <div className="position-navigate">
                       <button type="button" onClick={nextStep} className="btn btn-rescure">Next</button>
                    </div>
                </div>   
               
           </CSSTransition>

            <CSSTransition
               in={step === 2}
               timeout={500}
               classNames="fade"
               unmountOnExit
               nodeRef={pharmacySectionRef}
              >
                <div className="step" ref={pharmacySectionRef}>
                   <div className="flexregister">
                      <div className="reg-left-image" style={{backgroundImage: `url(${backImage03})`}}></div>
                      <div className="reg-right-form-data">             
                          <div className="reg-main">
                              <div className="reg-form">
                                       <h3>Pharmacy Details</h3>
                                       
                                      <div className="form-group frg">
                                          <div className="text-form">Pharmacy Label: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={titleRef}  placeholder="Enter Pharmacy Name" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Address Street: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={streetRef}  placeholder="Enter Street" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Address Town: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={townRef}  placeholder="Enter Town" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Address City: </div>
                                          <input type="text" className="form-control ct-content wide100"  ref={cityRef} placeholder="Enter City" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Address zip Code: </div>
                                          <input type="text" className="form-control ct-content wide100" ref={zipCodeyRef}  placeholder="Enter zip code" required/>
                                      </div>
                                      <div className="form-group frg">
                                          <div className="text-form">Province: </div>
                                          <select className="form-control ct-content wide100" ref={provinceRef} >
                                            <option value="Gauteng">Gauteng</option>
                                            <option value="Eastern Cape">Eastern Cape</option>
                                            <option value="Free State">Free State</option>
                                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                            <option value="Limpopo">Limpopo</option>                                            
                                            <option value="Mpumalanga">Mpumalanga</option>
                                            <option value="Northern Cape">Northern Cape</option>
                                            <option value="North West">North West</option>
                                            <option value="Western Cape">Western Cape</option>
                                          </select>
                                      </div>
                                    
                                     <div className="form-group frg">
                                      <button type="submit" className="btn btn-rescure-login btn-full" onClick={handleRegistration} disabled={isLoading}>Sign Up</button>
                                  </div>
                      
                              </div>                                 
                          </div>
                        </div>
                   </div>
                   <div className="position-navigate">
                       <button onClick={prevStep} className="btn btn-rescure-login mgright" >Restart</button>
                       
                    </div>
                </div>

            </CSSTransition>
           
      
        </div>
    </div>
  )
}

export default Register