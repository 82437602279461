import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import DlgVideoDisplay from '../Components/Dialogue/DlgVideoDisplay';
import { toast } from 'react-toastify';
import { logout } from '../reduxAuth/authSlice';

function Knowledge() {

  const {user}                                                = useSelector((state) => state.auth);
  const [knowledgeList, setKnowledgeList]                     = useState();
  const [globalFilter, setGlobalFilter]                       = useState('');
  const [selectedScriptsUser, setSelectedScriptsUser]         = useState(null);
  const [selectedVideo, setSelectedVideo]                     = useState();
  const [showWatchingVideo, setShowWatchingVideo]             = useState(false);
  const dispatch                                                           = useDispatch();

  useEffect(() => {
    getKnowledVidoes();
  },[])

  const getKnowledVidoes = async () => {
      try {
        
          const resVids = await axios.get(CONSTANTS.API_URL +"videos/list/topharm/client", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
        // console.log(")))))(((((");
        // console.log(resVids.data);
        setKnowledgeList(resVids.data);  
      } catch (err) {
          console.log(err);   
             //Check if token is invalid     
          if(err.response.status === 403){
            localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
            dispatch(logout());
            toast.error("Session has timeout out.")
            setTimeout(() => {
              document.location.href("/login");
            }, 4000);
          }     
      }        
  }
  //////////___
  const linkVideoPopTemplate = (rowData) => {
    return <Button label="View" className="btn btn-rescure btsm1" onClick={() => clickVideoDisplay(rowData)}/>
  }
  //////////___
  
  const clickVideoDisplay = (data) => {
    setSelectedVideo(data);
    setShowWatchingVideo(true)
  }
  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Knowledge</h2>
      <div className="card">
      <div className="display-video">
          { 
          knowledgeList && (
              <DlgVideoDisplay
                  showWatchingVideo={showWatchingVideo} 
                  setShowWatchingVideo={setShowWatchingVideo}
                  thisVideo={selectedVideo}
                  person={user}
              />
              )
            }
        </div>
         <div className="card-body">
              {
                knowledgeList && (
                    <DataTable
                            value={knowledgeList}
                            className="min-w-full"
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} videos"
                            responsiveLayout="scroll"
                            globalFilter={(globalFilter) ? globalFilter : ''}
                            selection={selectedScriptsUser}
                            onSelectionChange={e => setSelectedScriptsUser(e.value)}
                        >                      
                            <Column field="title" header="Title"></Column>
                            <Column field="videotype" header="Client"></Column>  
                            <Column field="privacy" header="Privacy"></Column>      
                            <Column header="View" body={linkVideoPopTemplate}></Column>                                             
                    </DataTable>
                )
            }
         </div>
      </div>
    </div>
  )
}

export default Knowledge